import React from 'react';
import './FormCard.css';

import {ApplicationForm} from '../../appl.constants';
import BusinessInformationForm from '../../appl-forms/bis-info/BusinessInformationForm';
import SiteSurvey from '../../appl-forms/site-survey/SiteSurveyForm';
import FinancialProfileForm from '../../appl-forms/fin-prof/FinancialProfileForm';
import OwnerInformationForm from '../../appl-forms/owner-info/OwnerInformationForm';
import ComplianceForm from '../../appl-forms/compliance-form/ComplianceForm';


export default function FormCard(props){
    function getForm(){
        if(props.form === ApplicationForm.BIS_INFO) return <BusinessInformationForm ref={props.bindToChildFormRef} {...props} />;
        // else if(props.form === ApplicationForm.SITE_SURVEY) return <SiteSurvey ref={props.bindToChildFormRef} {...props} />;
        else if(props.form === ApplicationForm.FIN_PROF) return <FinancialProfileForm ref={props.bindToChildFormRef} {...props} />;
        else if(props.form === ApplicationForm.OWNR_INFO) return <OwnerInformationForm ref={props.bindToChildFormRef} {...props} />;
        else if(props.form === ApplicationForm.CMPLNC) return <ComplianceForm ref={props.bindToChildFormRef} {...props} />;
    }

	function isChargeback() {
		return props.path === '/chargebacks'
	}

    function isAffiliates() {
        return props.path === '/affiliates'
    }

	function isAmerican() {
		return props.country === 'us'
	}

    return (
        <div className="FormCard" data-content-spill={props.form === ApplicationForm.OWNR_INFO}>
            {( isAmerican() || isChargeback() || isAffiliates() ) && <div className="Heading">{props.heading}</div>}
            <div className="Description">{props.description}</div>

            {getForm()}
        </div>
    );
}