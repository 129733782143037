import React, {useState, forwardRef, useImperativeHandle} from 'react';
import {Checkbox, Select, TextInput, RadioGroup, TagInput, SelectMenu, Button} from 'evergreen-ui';
import FormData from '../../services/form-data-collector.service';

// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.

export default forwardRef(function ComplianceForm(props, ref){

    const [pymn_prcss_gtwy_req, setpymn_prcss_gtwy_req]     = useState(FormData.pymn_prcss_gtwy_req || false);
    const [trmnl_req, settrmnl_req]                         = useState(FormData.trmnl_req || false);
    const [pymn_stndrs_compliant, setpymn_stndrs_compliant] = useState(FormData.pymn_stndrs_compliant || false);
    const [scrty_assr_fll_nm, setscrty_assr_fll_nm]         = useState(FormData.scrty_assr_fll_nm || "");
    const [lst_crtfcn_dt, setlst_crtfcn_dt]                 = useState(FormData.lst_crtfcn_dt);
    const [dat_compromis, setdat_compromis]                 = useState(FormData.dat_compromis || "No");
    const [compromis_remedifcn, setcompromis_remedifcn]     = useState(FormData.compromis_remedifcn);
    const [dat_stor, setdat_stor]                           = useState(FormData.dat_stor || "(Please Select)");
    const [pymnt_gtwys, setpymnt_gtwys]                     = useState(FormData.pymnt_gtwys || []);

    const dat_str_ops = [
        { label: 'Electronically', value: 'electronic' },
        { label: 'On Paper', value: 'on_paper' }
    ];
    
    let pymnt_gtways_ops = [
        "GROOVEKART", "GROOVESELL", "Authorize.Net", 
        "NMI", "Shopify", "Woo Commerce", "Others"
    ];
    pymnt_gtways_ops = pymnt_gtways_ops.map(op => ({"label": op, "value": op}));

    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        triggerPublishData(){
            publishDataToCollector();
        }
    }));

    function publishDataToCollector(){
        FormData.pymn_prcss_gtwy_req = pymn_prcss_gtwy_req;
        FormData.trmnl_req = trmnl_req;
        FormData.pymn_stndrs_compliant = pymn_stndrs_compliant;
        FormData.merch_pci_dss_compliant = pymn_stndrs_compliant ? "Yes": "No";
        FormData.scrty_assr_fll_nm = scrty_assr_fll_nm;
        FormData.lst_crtfcn_dt = lst_crtfcn_dt;
        FormData.dat_compromis = dat_compromis;
        FormData.compromis_remedifcn = compromis_remedifcn;
        FormData.dat_stor = dat_stor;
        FormData.pymnt_gtwys = pymnt_gtwys;
        FormData.dat_stor_ppr = (dat_stor === "on_paper") ? "Yes": "No";
        FormData.dat_stor_elec = (dat_stor === "electronic") ? "Yes": "No";
    }

    return(
        <React.Fragment>
            <Checkbox 
                label="Do you need a payment processing gateway?"
                checked={pymn_prcss_gtwy_req} 
                onChange={event => setpymn_prcss_gtwy_req(event.target.checked)}></Checkbox>

            <div className="ToggleShow" data-show={FormData.ofln_pymnt_avl || false}>
                <Checkbox
                    label="Do you need a point of sale terminal?"
                    checked={trmnl_req} 
                    onChange={event => settrmnl_req(event.target.checked)}></Checkbox>
            </div>

            <Checkbox 
                label="Is your business gateway Payment Card Industry Data Security Standards Compliant?"
                checked={pymn_stndrs_compliant}
                onChange={e => setpymn_stndrs_compliant(e.target.checked)} />

            <div className="Row">
                <div className="FillParent">
                    <div className="InputLabel">Security Assessor Full Name</div>
                    <TextInput name="scrty_assr_fll_nm"
                        value={scrty_assr_fll_nm}
                        onChange={e => setscrty_assr_fll_nm(e.target.value)}/>
                </div>

                <div className="FillParent">
                    <div className="InputLabel">Last Certification Date</div>
                    <TextInput name="lst_crtfcn_dt"
                        value={lst_crtfcn_dt}
                        type="date"
                        onChange={e => setlst_crtfcn_dt(e.target.value)}/>
                </div>
            </div>

            <div className="Row">
                <div className="FillParent">
                    <div className="InputLabel">Has there been a Data Compromise?</div>
                    <Select value={dat_compromis}
                        onChange={e => setdat_compromis(e.target.value)}>
                        <option>Yes</option>
                        <option>No</option>
                    </Select>
                </div>
                <div className="FillParent">
                    <div className="InputLabel">Remidification after Compromise?</div>
                    <Select value={compromis_remedifcn}
                        disabled={dat_compromis !== 'Yes'}
                        onChange={e => setcompromis_remedifcn(e.target.value)}>
                        <option>(Please Select)</option>
                        <option>Yes</option>
                        <option>No</option>
                    </Select>
                </div>
            </div>

            <div className="InputLabel">How do you store Card Data?</div>
            <RadioGroup
                value={dat_stor}
                options={dat_str_ops}
                onChange={value => setdat_stor(value)}
            />

            <div className="InputLabel">Select Payment Gateway Vendors:</div>
            <div className="Row">
                <div className="FillParent">
                    <TagInput
                        inputProps={{ placeholder: 'Add gateway...' }}
                        values={pymnt_gtwys}
                        onChange={values => setpymnt_gtwys(values)}
                    />
                </div>
                <div className="AddPaymentGatewayPopover">
                    <SelectMenu
                        isMultiSelect
                        options={pymnt_gtways_ops}
                        selected={pymnt_gtwys}
                        onSelect={selected => {
                            let updtd_pymnt_gtwys = [...pymnt_gtwys];
                            updtd_pymnt_gtwys.push(selected.value);
                            setpymnt_gtwys(updtd_pymnt_gtwys);
                        }}
                        onDeselect={deselected => {
                            let updtd_pymnt_gtwys = [...pymnt_gtwys];
                            let dslct_idx = updtd_pymnt_gtwys.indexOf(deselected.value);
                            updtd_pymnt_gtwys.splice(dslct_idx, 1);
                            setpymnt_gtwys(updtd_pymnt_gtwys);
                        }}>
                        <Button>Select Gateways</Button>
                    </SelectMenu>
                </div>
            </div>
        </React.Fragment>
    );
});