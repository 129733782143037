import { toaster } from "evergreen-ui";

function FormValidateService() {}

FormValidateService.prototype.validateBusinessWebsite = function (address) {
  address = address || "";

  if (address.length === 0) {
    toaster.warning("Website can't be empty");
  } else if (!address.includes("https://")) {
    toaster.warning("Please include https:// in website");
  }
};

FormValidateService.prototype.requiredFieldValidation = function (
  val,
  fieldLabel
) {
  val = val || "";
  // console.log("form validation", fieldLabel, val);
  if (val.length === 0) {
    toaster.warning(fieldLabel + " is required.");
  }
};

export default new FormValidateService();
