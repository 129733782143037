import React from 'react';
import './FormNavbar.css';
import {l} from '../../Functions';

function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
		});
		
    return result;
}

export default function FormNavbar(props){
    const FormDataProvider = props.FormDataProvider;
    let formSections = [l("Business Information")];
    let icons = ["store-3"];
    
    if( !(findGetParameter('refId') === 'groovekart' || findGetParameter('refId') === 'groovekartpaid' || findGetParameter('refId') === 'groovesell' || findGetParameter('refId') === 'groovefunnel') && !isChargeback() && !isAffiliates() ){
        icons.push("map-pin-5");
    }
    
    formSections.push(l("Banking information"))
    icons.push("briefcase");
    
    if( FormDataProvider.corp_type && FormDataProvider.corp_type !== 'Individual' && FormDataProvider.corp_type !== 'EIRELI' && !isAffiliates() ){
        formSections.push(l("Owner/Officer"))
        icons.push("user-4");
    }
    
    let sectionTabs = formSections.map(
        (sectionLabel, key) => <FormSectionTab key={key} 
            icon={icons[key]}
            label={sectionLabel} 
            active={sectionLabel === formSections[props.activeSectionIndex]}
            action={setActiveFormType}/>
    );

    function setActiveFormType(sectionName) {
        props.updateActiveFormType(formSections.indexOf(sectionName));
    }

    function isChargeback() {
        return window.location.pathname === '/chargebacks'
    }
    
    function isAffiliates() {
        return window.location.pathname === '/affiliates'
    }
    
    function isApplication() {
        return !( isChargeback() || isAffiliates() );
    }

    return(
        <div className="Position Fixed Row CenterV CenterH FormNavbar" style={props.style}>
            {sectionTabs}
        </div>
    );
}

function FormSectionTab(props){

    function sectionTabClickHandler(){
        props.action(props.label);
    }

    return(
        <div className="Row CenterV FormSectionTab" data-active={props.active}
            // onClick={sectionTabClickHandler}
        >
                <i className={'ri-' + props.icon + '-fill'}></i>
                <span>{props.label}</span>
        </div>
    );
}