import React, {useState, forwardRef, useImperativeHandle} from 'react';
import {TextInput, Select, Checkbox, Combobox, Radio, SelectMenu, Button} from 'evergreen-ui';
import {getCountries, getMCCMapping} from '../../appl.constants';
import FormData from '../../services/form-data-collector.service';
import FormValidateService from '../../services/form-validation.service';
import NumberFormat from 'react-number-format';
import ReactTooltip from "react-tooltip";
import question from '../../assets/img/question.svg'
import MyEvent from '../../MyEvent'
import {l} from '../../Functions'

// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.


function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
		});
		
    return result;
}

export default forwardRef(function BusinessInformationForm(props, ref){
    let pymnt_gtways_ops;
    
    if( isBrazilian() )
        pymnt_gtways_ops = ["GROOVEKART", "GROOVESELL", "WooCommerce", "Shopify", "MercadoPago", "PagSeguro", "Pagar.me", "Rede"];
    else
        pymnt_gtways_ops = ["GROOVEKART", "GROOVESELL", "Authorize.Net", "NMI", "Shopify", "WooCommerce","Limelight","Konnective","Keap.io","Virtual Terminal ( Where I enter Credit card Manually)","ClickFunnels","Magento","Prestashop","FluidPay", "Others"];

    pymnt_gtways_ops = pymnt_gtways_ops.map(op => ({"label": op, "value": op}));

    
    let prcss_stmnt_cnt_dd = new Array(12).fill(1);
    prcss_stmnt_cnt_dd = prcss_stmnt_cnt_dd.map((val, itr) => val + itr);
    prcss_stmnt_cnt_dd = prcss_stmnt_cnt_dd.map((op, key) => <option key={key}>{op}</option>);
    
    /**
     * State Variables Declaration
     */
    const [selectedEntityType, setselectedEntityType]       = useState(FormData.corp_type || '');
    const [own_frst_nm, setown_frst_nm]                     = useState(FormData.own_frst_nm || '');
    const [own_middle_nm, setown_middle_nm]                 = useState(FormData.own_middle_nm || '');
    const [own_last_nm, setown_last_nm]                     = useState(FormData.own_last_nm || '');
    const [lgl_nm, setlgl_nm]                               = useState(FormData.lgl_nm || '');
    const [dba_nm, setdba_nm]                               = useState(FormData.dba_nm || '');
    const [bil_adr, setbil_adr]                             = useState(FormData.bil_adr || '');
    const [bil_adr_city, setbil_adr_city]                   = useState(FormData.bil_adr_city || '');
    const [bil_adr_state, setbil_adr_state]                 = useState(FormData.bil_adr_state || '');
    const [bil_adr_zip, setbil_adr_zip]                     = useState(FormData.bil_adr_zip || '');
    const [dba_adr, setdba_adr]                             = useState(FormData.dba_adr || '');
    const [dba_adr_city, setdba_adr_city]                   = useState(FormData.dba_adr_city || '');
    const [dba_adr_state, setdba_adr_state]                 = useState(FormData.dba_adr_state || '');
    const [dba_adr_zip, setdba_adr_zip]                     = useState(FormData.dba_adr_zip || '');
    const [sameAddrAsDBA, setsameAddrAsDBA]                 = useState(FormData.sameAddrAsDBA || true);
    const [bis_country, setbis_country]                     = useState(FormData.bis_country || '');
    const [lookng_for_new_processor, setlookng_for_new_processor] = useState(FormData.lookng_for_new_processor || false);
    const [procss_stmnt_cnt, setprocss_stmnt_cnt]           = useState(FormData.procss_stmnt_cnt || 0);
    const [merch_dclnd_by_prcssr, setmerch_dclnd_by_prcssr] = useState(FormData.merch_dclnd_by_prcssr || false);
    const [nvr_acpted_card, setnvr_acpted_card]             = useState(FormData.nvr_acpted_card !== undefined ? FormData.nvr_acpted_card:true);
    const [ofln_pymnt_avl, setofln_pymnt_avl]               = useState(FormData.ofln_pymnt_avl || false);
    const [onln_pymnt_avl, setonln_pymnt_avl]               = useState(FormData.onln_pymnt_avl || false);
    const [rfnd_polc, setrfnd_polc]                         = useState(FormData.rfnd_polc);
    const [fed_tax_id,  setfed_tax_id]                      = useState(FormData.fed_tax_id || '');
    const [ownr_drv_lis,  setownr_drv_lis]                  = useState(FormData.ownr_drv_lis);
    const [ownr_dob,  setownr_dob]                          = useState(FormData.ownr_dob);
    const [ownrbr_cpf, setownrbr_cpf]                       = useState(FormData.ownrbr_cpf);
    const [ownrbr_rg, setownrbr_rg]                         = useState(FormData.ownrbr_rg);
    const [ownr_ssn, setownr_ssn]                           = useState(FormData.ownr_ssn);
    const [ownr_passport_number, setownr_passport_number]   = useState(FormData.ownr_passport_number);
    const [bis_email, setbis_email]                         = useState(FormData.bis_email || '');
    const [bis_website, setbis_website]                     = useState(FormData.bis_website || '');
    const [cust_sup_phn, setcust_sup_phn]                   = useState(FormData.cust_sup_phn || '');
    const [bus_stat_dat, setbus_stat_dat]                   = useState(FormData.bus_stat_dat || '');
    const [annl_vol, setannl_vol]                           = useState(FormData.annl_vol || 0);
    const [avg_tkt, setavg_tkt]                             = useState(FormData.avg_tkt || '');
    const [hgh_tkt, sethgh_tkt]                             = useState(FormData.hgh_tkt || '');
    const [mcc_code, setmcc_code]                           = useState(FormData.mcc_code || '');
    const [gds_srvcs_sold, setgds_srvcs_sold]               = useState(FormData.gds_srvcs_sold || '');
    const [rfnd_polc_othr, setrfnd_polc_othr]               = useState(FormData.rfnd_polc_othr || '');
    const [onln_pymnt_prcnt, setonln_pymnt_prcnt]           = useState(FormData.onln_pymnt_prcnt);
    const [nm_of_prcssrs_who_dclnd_mrch, setnm_of_prcssrs_who_dclnd_mrch] = useState(FormData.nm_of_prcssrs_who_dclnd_mrch || '');
    const [rsnd_for_mrch_shtdwn, setrsnd_for_mrch_shtdwn]   = useState(FormData.rsnd_for_mrch_shtdwn || '');
    const [cnpj, setcnpj]   = useState(FormData.cnpj || '');
    const [missingElement, setMElement]   = useState(FormData.mElement || []);
    let ofln_pymnt_prcnt                                    = FormData.ofln_pymnt_prcnt;

    
    const [gds_dlvr_mode, setgds_dlvr_mode] = useState(FormData.gds_dlvr_mode);
    const [ff_hous_usd, setff_hous_usd] = useState( FormData.ff_hous_usd || false);
    const [ff_hous_lctn_typ, setff_hous_lctn_typ] = useState(FormData.ff_hous_lctn_typ);
    const [ff_hous_cntct, setff_hous_cntct] = useState(FormData.ff_hous_cntct || '');
    const [ff_hous, setff_hous] = useState(FormData.ff_hous || '');
    const [pymnt_gtwys, setpymnt_gtwys] = useState(FormData.pymnt_gtwys || []);
    
    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        triggerPublishData(){
            publishDataToCollector();
            setMElement(FormData.mElement);
        }
    }));

    function monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : ((months-(months%12))/12)+'/'+(months%12);
    }

    function publishDataToCollector(){
        ofln_pymnt_prcnt = (ofln_pymnt_avl && onln_pymnt_avl) ? (100 - onln_pymnt_prcnt): 0;
        FormData.own_middle_nm = own_middle_nm;
        FormData.own_full_nm = own_frst_nm+' '+own_middle_nm+' '+own_last_nm;

        FormData.bil_adr = sameAddrAsDBA ? dba_adr: bil_adr;
        FormData.bil_adr_city = sameAddrAsDBA ? dba_adr_city: bil_adr_city;
        FormData.bil_adr_state = sameAddrAsDBA? dba_adr_state: bil_adr_state;
        FormData.bil_adr_zip = sameAddrAsDBA ? dba_adr_zip: bil_adr_zip;
        
        FormData.dba_adr = dba_adr;
        FormData.dba_adr_city = dba_adr_city;
        FormData.dba_adr_state = dba_adr_state;
        FormData.dba_adr_zip = dba_adr_zip;
        
        FormData.bil_full_adr = '';

        if( (sameAddrAsDBA && dba_adr) || bil_adr )
            FormData.bil_full_adr += sameAddrAsDBA ? dba_adr_city:bil_adr_city;

        if( (sameAddrAsDBA && dba_adr_state) || bil_adr_state )
            FormData.bil_full_adr += ', '+(sameAddrAsDBA ? dba_adr_state:bil_adr_state);

        if( (sameAddrAsDBA && dba_adr_zip) || bil_adr_zip )
            FormData.bil_full_adr += ', '+(sameAddrAsDBA ? dba_adr_zip:bil_adr_zip);
        
        
        FormData.dba_full_adr = '';

        if( dba_adr )
            FormData.dba_full_adr += dba_adr_city;

        if( dba_adr_state )
            FormData.dba_full_adr += ', '+dba_adr_state;

        if( dba_adr_zip )
            FormData.dba_full_adr += ', '+dba_adr_zip;


        FormData.sameAddrAsDBA = sameAddrAsDBA;
        FormData.corp_type = selectedEntityType;
        FormData.ownr_drv_lis = ownr_drv_lis;
        FormData.ownr_ssn = ownr_ssn ||'';
        FormData.ownr_passport_number = ownr_passport_number ||'';
        FormData.bis_country = bis_country;
        FormData.lookng_for_new_processor = lookng_for_new_processor?1:0;

        FormData.processor_document_1 = lookng_for_new_processor?1:0;
        FormData.processor_document_2 = lookng_for_new_processor?1:0;
        FormData.processor_document_3 = lookng_for_new_processor?1:0;


        // FormData.procss_stmnt_cnt = procss_stmnt_cnt;
        FormData.merch_dclnd_by_prcssor = merch_dclnd_by_prcssr;
        FormData.nm_of_prcssrs_who_dclned_mrch = nm_of_prcssrs_who_dclnd_mrch;
        FormData.rsnd_for_mrch_shtdown = rsnd_for_mrch_shtdwn;
        FormData.nvr_acpted_card = nvr_acpted_card?1:0;
        FormData.ofln_pymnt_avl = ofln_pymnt_avl;
        FormData.onln_pymnt_avl = onln_pymnt_avl;
        FormData.rfnd_polc = rfnd_polc;
        FormData.fed_tax_id = fed_tax_id ? fed_tax_id.replace('-',''):'';
        
        FormData.bis_email = bis_email;

        if( bis_email ){
            FormData.cbd_url = 'https://app.groovepay.com/confirm/cbd/'+bis_email;
            FormData.cs_url = 'https://app.groovepay.com/confirm/cs/'+bis_email;
            FormData.wp_url = 'https://app.groovepay.com/confirm/wp/'+bis_email;
            FormData.lr_url = 'https://app.groovepay.com/confirm/lr/'+bis_email;
        }


        FormData.bis_website = bis_website;
        FormData.cust_sup_phn = cust_sup_phn.replaceAll('-','');
        FormData.cust_dba_phn = cust_sup_phn.replaceAll('-','');
        FormData.corp_phn = cust_sup_phn.replaceAll('-','');

        if( selectedEntityType === 'Individual' || selectedEntityType === 'EIRELI' ) {
            FormData.ownrctrl_fname = own_frst_nm;
            FormData.ownrctrl_lname = own_last_nm;
            FormData.ownrctrl_dbirth = ownr_dob;
            
            FormData.ownrctrl_title = 'Owner';
            FormData.ownrctrl_ownrsh = '100';
            FormData.ownrctrl_adr = dba_adr;
            FormData.ownrctrl_city = dba_adr_city;
            FormData.ownrctrl_state = dba_adr_state;
            FormData.ownrctrl_zip = dba_adr_zip;
            
            FormData.ownrshp_pcnt = '100';
            FormData.ownr_title = 'Owner';
            
            if( isAmerican() )
                FormData.ownrctrl_ssn = ownr_ssn;
        }

        FormData.ownr_mail = bis_email;
        FormData.ownr_frst_nm = own_frst_nm;
        FormData.ownr_middle_nm = own_middle_nm;
        FormData.ownr_last_nm = own_last_nm;
        FormData.ownr_full_nm = own_frst_nm+' '+own_middle_nm+' '+own_last_nm;
    
        // FormData.ownr_addr = dba_adr;
        // FormData.ownr_addr_city = dba_adr_city;
        // FormData.ownr_addr_state = dba_adr_state;
        // FormData.ownr_addr_zip = dba_adr_zip;
        FormData.ownr_cntry = bis_country;
        FormData.ownr_phn = cust_sup_phn;
        
        FormData.ownr_drv_lis = ownr_drv_lis || '';
        FormData.ownr_drv_state = '';
        FormData.ownr_issuing_country = '';

        FormData.ownr_doc_expiration_date = '';

        FormData.ownrctrl_service_email = bis_email;
        FormData.ownrctrl_service_phone = cust_sup_phn.replace('-','');
        

        FormData.bus_stat_dat = bus_stat_dat;
        FormData.bus_stat_dat_count = bus_stat_dat && monthDiff(new Date(bus_stat_dat.split('-')[0],bus_stat_dat.split('-')[1],bus_stat_dat.split('-')[2]), new Date()) || '';
        FormData.annl_vol = annl_vol ? annl_vol.replace(/[R$]/g,''):'';
        FormData.avg_tkt = avg_tkt ? avg_tkt.replace(/[R$]/g,''):'';
        FormData.hgh_tkt = hgh_tkt ? hgh_tkt.replace(/[R$]/g,''):'';
        FormData.mcc_code = mcc_code;
        FormData.gds_srvcs_sold = gds_srvcs_sold;
        FormData.rfnd_polc_othr = rfnd_polc_othr;
        FormData.onln_pymnt_prcnt = onln_pymnt_prcnt;
        FormData.ofln_pymnt_prcnt = ofln_pymnt_prcnt;
        FormData.seasonal_sales = 3;

        FormData.dat_compromis = 'No';
        FormData.dat_stor_ppr = 'No';
        FormData.dat_stor_elec = 'No';
        FormData.merch_pci_dss_compliant = 'Yes';

        // handle Online/Offline Payment Mode %cent share opts
        if(onln_pymnt_avl && !ofln_pymnt_avl){
            FormData.bis_info_card_nt_prsnt_prcnt = 100;
            FormData.bis_info_intrnt_share = 100;
            FormData.bis_info_card_present = 0;
            FormData.bis_info_card_swipe = 0;

        }else if(!onln_pymnt_avl & ofln_pymnt_avl){
            FormData.bis_info_card_nt_prsnt_prcnt = 0;
            FormData.bis_info_intrnt_share = 0;
            FormData.bis_info_card_present = 100;
            FormData.bis_info_card_swipe = 0;
        }else{
            FormData.bis_info_card_nt_prsnt_prcnt = onln_pymnt_prcnt;
            FormData.bis_info_intrnt_share = onln_pymnt_prcnt;
            FormData.bis_info_card_present = ofln_pymnt_prcnt;
            FormData.bis_info_card_swipe = 0;
        }

        // Hidden Fields
        if( annl_vol ){
            const int_annl_vol = parseInt(annl_vol.replace(/[R$]/g,'').split(',').join(''));
            FormData.yrly_amex = parseInt(int_annl_vol/10);
            FormData.yrly_vdm = parseInt(int_annl_vol*9/10);
            FormData.mo_amex_vol = parseInt(int_annl_vol/120);
            FormData.vmd_monthly = parseInt(int_annl_vol*9/120);
            FormData.monthly_volume = parseInt(int_annl_vol/12);
        }
        // End Of Hidden Fields

        FormData.gds_dlvr_mode = gds_dlvr_mode;
        FormData.pymn_stndrs_compliant = true;
        FormData.pymnt_gtwys = pymnt_gtwys;

        if(gds_dlvr_mode === 'Digitally'){
            FormData.ff_hous_usd = '';
            FormData.ff_hous_lctn_typ = '';
            FormData.ff_hous_cntct = '';
            FormData.ff_hous = '';
        }else{
            FormData.ff_hous_usd = ff_hous_usd;
            FormData.ff_hous_lctn_typ = ff_hous_lctn_typ;
            FormData.ff_hous_cntct = ff_hous_cntct;
            FormData.ff_hous = ff_hous;
        }
    }

    function updateOwnerFirstName(first_name){
        setown_frst_nm(first_name);
    }

	function inArray(needle, haystack) {
        if( needle && haystack ){
            var length = haystack.length;
            for(var i = 0; i < length; i++) {
                if(haystack[i] == needle) return true;
            }
        }

        return false;
    }
    
    function isFieldNotFilled(target) {
        return inArray(target,missingElement) && !FormData[target];
    }

    function isAmerican(){
        return props.country === 'us'
    }

    function isBrazilian(){
        return props.country === 'br'
    }

    function isChargeback(){
        return props.path === '/chargebacks'
    }

    function isAffiliates() {
        return props.path === '/affiliates'
    }

    function isApplication() {
        return !( isChargeback() || isAffiliates() );
    }

    return(
        <React.Fragment>
            <div className="Row">
                <div className="FillParent">
                    <div className="InputLabel">{l('First name')}*</div>
                    <TextInput 
                        name="own_frst_nm"
                        value={own_frst_nm}
                        onBlur={e => {
                            FormValidateService.requiredFieldValidation(e.target.value, l("First name"));
                        }}
                        onChange={e => {
                            updateOwnerFirstName(e.target.value)
                            FormData.own_frst_nm = e.target.value;
                        }}
                        className={isFieldNotFilled('own_frst_nm')?'missing':''}
                    />
                </div>
                {
                    isApplication() && 
                    <div className="FillParent" style={{width: 100,flex: 'none'}}>
                        <div className="InputLabel">{l('Middle initial')}</div>
                        <TextInput name="own_middle_nm"
                            value={own_middle_nm} 
                            onChange={e => setown_middle_nm(e.target.value)}/>
                    </div>
                }
                <div className="FillParent">
                    <div className="InputLabel">{l('Last name')}*</div>
                    <TextInput name="own_last_nm"
                        value={own_last_nm} 
                        onBlur={e => FormValidateService.requiredFieldValidation(e.target.value, l("Last name"))}
                        onChange={e => {
                            setown_last_nm(e.target.value)
                            FormData.own_last_nm = e.target.value;
                        }}
                        className={isFieldNotFilled('own_last_nm')?'missing':''}
                        />
                </div>
            </div>
            <div className="Row">
                <div className="FillParent">
                    <div className="InputLabel">{l('Legal business name')}*</div>
                    <TextInput name="lgl_nm"
                        value={lgl_nm}
                        onBlur={e => FormValidateService.requiredFieldValidation(e.target.value, l("Legal Business Name"))}
                        onChange={e => {
                            setlgl_nm(e.target.value)
                            FormData.lgl_nm = e.target.value;
                        }}
                        className={isFieldNotFilled('lgl_nm')?'missing':''}
                    />
                </div>
                {
                    ( isChargeback() || isAffiliates() ) && 
                    <div className="FillParent">
                        <div className="InputLabel">{l('Type of Corporation')}*</div>
                        <Select
                            name="selectedEntityType"
                            value={selectedEntityType}
                            onChange={selected => {
                                setselectedEntityType(selected.target.value)
                                FormData.corp_type = selected.target.value;
                                MyEvent.update();
                            }}
                            className={isFieldNotFilled('corp_type')?'missing':''}
                        >
                            <option value="">{l('(Please Select)')}</option>
                            <option value="Company">{l('Company')}</option>
                            <option value="Individual">{l('Individual')}</option>
                            <option value="Nonprofit">{l('Nonprofit')}</option>
                            <option value="Government entity">{l('Government entity')}</option>
                        </Select>
                    </div>
                }
                {
                    ( isChargeback() || isAffiliates() ) && 
                    selectedEntityType !== '' && 
                    selectedEntityType !== 'Individual' &&
                    <div className="FillParent">
                        <div className="InputLabel">{l('EIN number')}*</div>

                        <TextInput
                            name="fed_tax_id"
                            value={fed_tax_id || ''}
                            disabled={selectedEntityType === ''}
                            onChange={e => setfed_tax_id(e.target.value)}
                            className={isFieldNotFilled('fed_tax_id')?'missing':''}
                        />
                    </div>
                }
                {
                    isApplication() && 
                    <div className="FillParent">
                        <div className="InputLabel">{l('DBA Name')}*</div>
                        <TextInput name="dba_nm"
                            value={dba_nm}
                            onChange={e => {
                                setdba_nm(e.target.value)
                                FormData.dba_nm = e.target.value;
                            }}
                            className={isFieldNotFilled('dba_nm')?'missing':''}
                        />
                    </div>
                }
                {
                    isApplication() &&
                    <div className="FillParent">
                        <div className="InputLabel">{l('In which country is the business registred')}*</div>
                        <Combobox
                            openOnFocus
                            items={getCountries()}
                            autocompleteProps={{
                                title: l('Choose Country')
                            }}
                            placeholder={l("(Please Select)")}
                            initialSelectedItem = {bis_country}
                            onChange={selected => {
                                setbis_country(selected)
                                FormData.bis_country = selected;

                                if( selected === 'United States of America' )
                                    props.setCountry('us')
                                else if( selected === 'Brasil' )
                                    props.setCountry('br')
                                else
                                    props.setCountry('other')
                            }}
                            className={isFieldNotFilled('bis_country')?'missing':''}
                        />
                    </div>
                }
            </div>

            {
                isApplication() &&
                <div className="Row">
                    <div className="FillParent">
                        <Checkbox 
                            label={l("Use Business Location address as Billing Address")}
                            checked={sameAddrAsDBA} 
                            onChange={event => setsameAddrAsDBA(event.target.checked)}></Checkbox>
                    </div>
                </div> 
            }
            
            <div className="Row">
                <div className="FillParent">
                    <div className="InputLabel">{l('Business Physical Address')}*</div>
                    <TextInput 
                        resize="none" 
                        value={dba_adr}
                        placeholder={l("Please do not enter a PO Box Addresses here. Residential addresses are allow for home-based business.")}
                        onBlur={e => FormValidateService.requiredFieldValidation(e.target.value, l("Business Physical Address"))}
                        onChange={e => {
                            setdba_adr(e.target.value)
                            FormData.dba_adr = e.target.value;

                            if( sameAddrAsDBA )
                                FormData.bil_adr = e.target.value;
                        }}
                        className={isFieldNotFilled('dba_adr')?'missing':''}
                    />
                </div>
                {
                    !(isChargeback() || isAffiliates()) && !sameAddrAsDBA &&
                    <div className="FillParent">
                        <div className="InputLabel">{l('Billing Address')}*</div>
                        <TextInput 
                            resize="none" 
                            value={bil_adr}
                            placeholder={l("Please do not enter a PO Box Addresses here. Residential addresses are allow for home-based business.")}
                            onBlur={e => FormValidateService.requiredFieldValidation(e.target.value, l("Billing Address"))}
                            onChange={e => {
                                setbil_adr(e.target.value)
                                FormData.bil_adr = e.target.value;
                            }}
                            className={ isFieldNotFilled('bil_adr')?'missing':'' }
                        />
                    </div>
                }
            </div>

            <div className="Row">
                <div className="FillParent">
                    <div className="Row">
                        <div className="FillParent">
                            <div className="InputLabel">{l('City')}</div>
                            <TextInput 
                                value={dba_adr_city}
                                onChange={e => setdba_adr_city(e.target.value)}
                            />
                        </div>

                        <div className="FillParent">
                            <div className="InputLabel">{l('State')}</div>
                            {
                                isAmerican() && 
                                <Select
                                    name="dba_adr_state"
                                    value={dba_adr_state}
                                    onChange={e => {
                                        setdba_adr_state(e.target.value)
                                    }}
                                >
                                    <option value=''>{l('State')}</option>
                                    <option value="AL">AL</option>
                                    <option value="AK">AK</option>
                                    <option value="AR">AR</option>	
                                    <option value="AZ">AZ</option>
                                    <option value="CA">CA</option>
                                    <option value="CO">CO</option>
                                    <option value="CT">CT</option>
                                    <option value="DC">DC</option>
                                    <option value="DE">DE</option>
                                    <option value="FL">FL</option>
                                    <option value="GA">GA</option>
                                    <option value="HI">HI</option>
                                    <option value="IA">IA</option>	
                                    <option value="ID">ID</option>
                                    <option value="IL">IL</option>
                                    <option value="IN">IN</option>
                                    <option value="KS">KS</option>
                                    <option value="KY">KY</option>
                                    <option value="LA">LA</option>
                                    <option value="MA">MA</option>
                                    <option value="MD">MD</option>
                                    <option value="ME">ME</option>
                                    <option value="MI">MI</option>
                                    <option value="MN">MN</option>
                                    <option value="MO">MO</option>	
                                    <option value="MS">MS</option>
                                    <option value="MT">MT</option>
                                    <option value="NC">NC</option>	
                                    <option value="NE">NE</option>
                                    <option value="NH">NH</option>
                                    <option value="NJ">NJ</option>
                                    <option value="NM">NM</option>			
                                    <option value="NV">NV</option>
                                    <option value="NY">NY</option>
                                    <option value="ND">ND</option>
                                    <option value="OH">OH</option>
                                    <option value="OK">OK</option>
                                    <option value="OR">OR</option>
                                    <option value="PA">PA</option>
                                    <option value="RI">RI</option>
                                    <option value="SC">SC</option>
                                    <option value="SD">SD</option>
                                    <option value="TN">TN</option>
                                    <option value="TX">TX</option>
                                    <option value="UT">UT</option>
                                    <option value="VT">VT</option>
                                    <option value="VA">VA</option>
                                    <option value="WA">WA</option>
                                    <option value="WI">WI</option>	
                                    <option value="WV">WV</option>
                                    <option value="WY">WY</option>
                                </Select> || <TextInput 
                                    value={dba_adr_state}
                                    onChange={e => {
                                        setdba_adr_state(e.target.value)
                                    }}
                                />
                            }
                        </div>

                        <div className="FillParent">
                            <div className="InputLabel">{l('ZIP')}</div>
                            <TextInput 
                                value={dba_adr_zip}
                                onChange={e => setdba_adr_zip(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
                
                {
                    isApplication() && !sameAddrAsDBA &&
                    <div className="FillParent">
                        <div className="Row">
                            <div className="FillParent">
                                <div className="InputLabel">{l('City')}</div>
                                <TextInput 
                                    value={bil_adr_city}
                                    onChange={e => {
                                        setbil_adr_city(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="FillParent">
                                <div className="InputLabel">{l('State')}</div>
                                {
                                    isAmerican() && 
                                    <Select 
                                        value={bil_adr_state}
                                        onChange={e => {
                                            setbil_adr_state(e.target.value)
                                        }}
                                    >
                                        <option value=''>{l('State')}</option>
                                        <option value="AL">AL</option>
                                        <option value="AK">AK</option>
                                        <option value="AR">AR</option>	
                                        <option value="AZ">AZ</option>
                                        <option value="CA">CA</option>
                                        <option value="CO">CO</option>
                                        <option value="CT">CT</option>
                                        <option value="DC">DC</option>
                                        <option value="DE">DE</option>
                                        <option value="FL">FL</option>
                                        <option value="GA">GA</option>
                                        <option value="HI">HI</option>
                                        <option value="IA">IA</option>	
                                        <option value="ID">ID</option>
                                        <option value="IL">IL</option>
                                        <option value="IN">IN</option>
                                        <option value="KS">KS</option>
                                        <option value="KY">KY</option>
                                        <option value="LA">LA</option>
                                        <option value="MA">MA</option>
                                        <option value="MD">MD</option>
                                        <option value="ME">ME</option>
                                        <option value="MI">MI</option>
                                        <option value="MN">MN</option>
                                        <option value="MO">MO</option>	
                                        <option value="MS">MS</option>
                                        <option value="MT">MT</option>
                                        <option value="NC">NC</option>	
                                        <option value="NE">NE</option>
                                        <option value="NH">NH</option>
                                        <option value="NJ">NJ</option>
                                        <option value="NM">NM</option>			
                                        <option value="NV">NV</option>
                                        <option value="NY">NY</option>
                                        <option value="ND">ND</option>
                                        <option value="OH">OH</option>
                                        <option value="OK">OK</option>
                                        <option value="OR">OR</option>
                                        <option value="PA">PA</option>
                                        <option value="RI">RI</option>
                                        <option value="SC">SC</option>
                                        <option value="SD">SD</option>
                                        <option value="TN">TN</option>
                                        <option value="TX">TX</option>
                                        <option value="UT">UT</option>
                                        <option value="VT">VT</option>
                                        <option value="VA">VA</option>
                                        <option value="WA">WA</option>
                                        <option value="WI">WI</option>	
                                        <option value="WV">WV</option>
                                        <option value="WY">WY</option>
                                    </Select> || <TextInput 
                                        value={bil_adr_state}
                                        onChange={e => {
                                            setbil_adr_state(e.target.value)
                                        }}
                                    />
                                }
                            </div>
                            <div className="FillParent">
                                <div className="InputLabel">{l('ZIP')}</div>
                                <TextInput 
                                    value={bil_adr_zip}
                                    onChange={e => {
                                        setbil_adr_zip(e.target.value)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                }

            </div>
            {/* Contact Form */}
            <div className="Row">
                <div className="FillParent">
                    <div className="InputLabel">{isAffiliates() && l('Contact Email') || l('Customer Service Email')}*</div>
                    <TextInput name="bis_email"
                        value={bis_email}
                        onBlur={e => FormValidateService.requiredFieldValidation(e.target.value, isAffiliates() && l('Contact Email') || l('Business Email'))}
                        onChange={e => {
                            setbis_email(e.target.value);
                            FormData.bis_email = e.target.value;
                        }}
                        className={isFieldNotFilled('bis_email')?'missing':''}
                    />
                </div>

                {
                    !isAffiliates() &&
                    <div className="FillParent">
                        <div className="InputLabel">{l('Website')}*</div>
                        <div style={{position:'relative'}}>
                            <span className="prefix">https://</span>
                            <input 
                                type="text"
                                className={`myinput ${isFieldNotFilled('bis_website')?'missing':''}`}
                                value={bis_website}
                                placeholder="example.com"
                                onChange={e => {
                                    setbis_website(e.target.value)
                                    FormData.bis_website = e.target.value;
                                }}
                                onBlur={e => FormValidateService.requiredFieldValidation(e.target.value,l('Website'))}
                            />
                        </div>
                    </div>
                }

                <div className="FillParent">
                    <div className="InputLabel">{l('Customer Support Phone')}*</div>
                    <TextInput name="cust_sup_phn"
                        value={cust_sup_phn}
                        placeholder={l("Phone number")}
                        onChange={e => {
                            setcust_sup_phn(e.target.value);
                            FormData.cust_sup_phn = e.target.value;
                        }}
                        className={isFieldNotFilled('cust_sup_phn')?'missing':''}
                    />
                </div>

                {
                    !isAmerican() && 
                    !( isChargeback() || isAffiliates() ) &&
                    <div className="FillParent">
                        <div className="InputLabel">{l('Type Of Product Sold')}</div>
                        <TextInput name="gds_srvcs_sold"
                            value={gds_srvcs_sold}
                            onChange={e => setgds_srvcs_sold(e.target.value)}
                            placeholder={l("Please briefly explain your business")}
                        />
                    </div>
                }
            </div>

            {
                isApplication() &&
                <div className="Heading MarginTop6x">{l('Business Profile and Assumptions')}</div>
            }

            {
                isApplication() &&
                <div className="Row">
                    <div className="FillParent">
                        <div className="InputLabel">{l('Type of Corporation')}*</div>
                        {
                            isBrazilian() &&
                            <Select
                                name="selectedEntityType"
                                value={selectedEntityType}
                                onChange={selected => {
                                    setselectedEntityType(selected.target.value)
                                    FormData.corp_type = selected.target.value;
                                    MyEvent.update();
                                }}
                                className={isFieldNotFilled('corp_type')?'missing':''}
                            >
                                <option value="">{l('(Please Select)')}</option>
                                <option value="LTDA">{l('LTDA')}</option>
                                <option value="EIRELI">{l('EIRELI')}</option>
                                <option value="MEI">{l('MEI')}</option>
                                <option value="S.A.">{l('S.A.')}</option>
                                <option value="OUTROS">{l('OUTROS')}</option>
                            </Select>
                        }
                        {
                            isAmerican() &&
                            <Select
                                name="selectedEntityType"
                                value={selectedEntityType}
                                onChange={selected => {
                                    setselectedEntityType(selected.target.value)
                                    FormData.corp_type = selected.target.value;
                                    MyEvent.update();
                                }}
                                className={isFieldNotFilled('corp_type')?'missing':''}
                            >
                                <option value=''>{l('(Please Select)')}</option>
                                <option value="Corporation">{l('Corporation')}</option>
                                <option value="Limited Liability Company">{l('Limited Liability Company')}</option>
                                <option value='Company'>{l('Company')}</option>
                                <option value='Individual'>{l('Individual')}</option>
                                <option value='Nonprofit'>{l('Nonprofit')}</option>
                                <option value='Government entity'>{l('Government entity')}</option>
                            </Select> || 
                            <Select
                                name="selectedEntityType"
                                value={selectedEntityType}
                                onChange={selected => {
                                    setselectedEntityType(selected.target.value)
                                    FormData.corp_type = selected.target.value;
                                    MyEvent.update();
                                }}
                                className={isFieldNotFilled('corp_type')?'missing':''}
                            >
                                <option value=''>{l('(Please Select)')}</option>
                                <option value='Company'>{l('Company')}</option>
                                <option value='Individual'>{l('Individual')}</option>
                                <option value='Nonprofit'>{l('Nonprofit')}</option>
                                <option value='Government entity'>{l('Government entity')}</option>
                            </Select>
                        }
                    </div>
                    {
                        isApplication() && 
                        isBrazilian() && 
                        selectedEntityType !== '' && 
                        <div className="FillParent">
                            <div className="InputLabel">{l('CNPJ')}*</div>
                            <TextInput
                                value={cnpj}
                                onChange={e => {
                                    setcnpj(e.target.value)
                                    FormData.cnpj = e.target.value;
                                }}
                                className={isFieldNotFilled('cnpj')?'missing':''}
                            />
                        </div>
                    }
                    {
                        !isBrazilian() && 
                        selectedEntityType !== '' && 
                        selectedEntityType !== 'Individual' && 
                        <div className="FillParent">
                            <div className="InputLabel">{isAmerican()?l('Federal Tax ID'):l('Tax ID number')}*</div>

                            <TextInput
                                name="fed_tax_id"
                                value={fed_tax_id}
                                onChange={e => {
                                    setfed_tax_id(e.target.value)
                                    FormData.fed_tax_id = e.target.value;
                                }}
                                className={isFieldNotFilled('fed_tax_id')?'missing':''}
                            />
                        </div>
                    }
                    {
                        selectedEntityType === 'Individual' && 
                        <div className="FillParent">
                            <div className="InputLabel">{l('Driver License')}*</div>
                            <TextInput 
                                resize="none" 
                                value={ownr_drv_lis}
                                onChange={e => {
                                    setownr_drv_lis(e.target.value);
                                    FormData.ownr_drv_lis = e.target.value;
                                }}
                                className={isFieldNotFilled('ownr_drv_lis')?'missing':''}
                            />
                        </div>
                    }
                    {
                        isAmerican() && 
                        selectedEntityType === 'Individual' && 
                        <div className="FillParent">
                            <div className="InputLabel">{l('Social Security Number')}*</div>
                            <TextInput 
                                type="password"
                                resize="none" 
                                value={ownr_ssn}
                                onChange={e => {
                                    setownr_ssn(e.target.value);
                                    FormData.ownr_ssn = e.target.value;
                                }}
                                className={isFieldNotFilled('ownr_ssn')?'missing':''}
                            />
                        </div>
                    }
                    {
                        !isAmerican() && 
                        selectedEntityType === 'Individual' && 
                        <div className="FillParent">
                            <div className="InputLabel">{l('Passport number')}*</div>
                            <TextInput 
                                resize="none" 
                                value={ownr_passport_number}
                                onChange={e => {
                                    setownr_passport_number(e.target.value);
                                    FormData.ownr_passport_number = e.target.value;
                                }}
                                className={isFieldNotFilled('ownr_passport_number')?'missing':''}
                            />
                        </div>
                    }
                    {
                        selectedEntityType === 'Individual' && 
                        <div className="FillParent">
                            <div className="InputLabel">{l('Date of birth')}*</div>
                            <TextInput 
                                type="date"
                                resize="none" 
                                value={ownr_dob}
                                onChange={e => {
                                    setownr_dob(e.target.value);
                                    FormData.ownr_dob = e.target.value;
                                }}
                                className={isFieldNotFilled('ownr_dob')?'missing':''}
                            />
                        </div>
                    }
                    {
                        selectedEntityType === 'EIRELI' && 
                        <div className="FillParent">
                            <div className="InputLabel">{l('CPF')}*</div>
                            <TextInput 
                                value={ownrbr_cpf}
                                onChange={e => {
                                    setownrbr_cpf(e.target.value);
                                    FormData.ownrctrlbr_cpf = e.target.value;
                                    FormData.ownrbr_cpf = e.target.value;
                                }}
                                className={isFieldNotFilled('ownrbr_cpf')?'missing':''}
                            />
                        </div>
                    }
                    {
                        selectedEntityType === 'EIRELI' && 
                        <div className="FillParent">
                            <div className="InputLabel">{l('RG')}*</div>
                            <TextInput 
                                value={ownrbr_rg}
                                onChange={e => {
                                    setownrbr_rg(e.target.value);
                                    FormData.ownrctrlbr_rg = e.target.value;
                                    FormData.ownrbr_rg = e.target.value;
                                }}
                                className={isFieldNotFilled('ownrbr_rg')?'missing':''}
                            />
                        </div>
                    }
                </div>
            }

            {
                isApplication() &&
                <div className="Row">
                    <div className="FillParent">
                        <div className="InputLabel">{l('Business Start Date')}*</div>
                        <TextInput type="date" name="bus_stat_dat"
                            value={bus_stat_dat}
                            onChange={e => setbus_stat_dat(e.target.value)}/>
                    </div>

                    <div className="FillParent">
                        <div className="InputLabel">{l('Estimated Annual Volume')} <span data-for="tooltip-1" data-tip={l("What is your expected annual processing volume?")} data-event="click focus" className="tooltip"><img src={question} /></span></div>
                        <ReactTooltip id="tooltip-1" globalEventOff="click" />
                        <NumberFormat
                            name="annl_vol"
                            thousandSeparator={true} 
                            prefix={isBrazilian()?'R$':'$'} 
                            value={annl_vol}
                            onChange={e => {
                                const theValue = e.target.value.replace(/,/gi, '')
                                setannl_vol(theValue);
                                FormData.annl_vol = theValue;
                                MyEvent.update();
                            }}
                            className="myinput"
                        />
                    </div>
                    
                    <div className="FillParent">
                        <div className="InputLabel">{l('Average Ticket')}* <span data-for="tooltip-2" data-tip={l("What do you think your average credit card transaction will be?")} data-event="click focus" className="tooltip"><img src={question} /></span></div>
                        <ReactTooltip id="tooltip-2" globalEventOff="click" />
                        <NumberFormat
                            name="avg_tkt"
                            thousandSeparator={true} 
                            prefix={isBrazilian()?'R$':'$'} 
                            value={avg_tkt}
                            onChange={e => {
                                const theValue = e.target.value.replace(/,/gi, '')
                                setavg_tkt(theValue);
                                FormData.avg_tkt = theValue;
                                MyEvent.update();
                            }}
                            className={`myinput ${isFieldNotFilled('avg_tkt')?'missing':''}`}
                        />
                    </div>

                    <div className="FillParent">
                        <div className="InputLabel">{l('Highest Ticket')}* <span data-for="tooltip-3" data-tip={l("What do you think your highest transaction might be?")} data-event="click focus" className="tooltip"><img src={question} /></span></div>
                        <ReactTooltip id="tooltip-3" globalEventOff="click" />
                        <NumberFormat
                            name="hgh_tkt"
                            thousandSeparator={true} 
                            prefix={isBrazilian()?'R$':'$'} 
                            value={hgh_tkt}
                            onChange={e => {
                                const theValue = e.target.value.replace(/,/gi, '')
                                sethgh_tkt(theValue);
                                FormData.hgh_tkt = theValue;
                                MyEvent.update();
                            }}
                            className={`myinput ${isFieldNotFilled('hgh_tkt')?'missing':''}`}
                        />
                    </div>
                </div>
            }
            {
                isApplication() &&
                <div className="Heading MarginTop4x MarginBottom0x">{l('Payment Processing Information')}</div>
            }
            {
                isApplication() &&
                <div className="Row">
                    <div className="FillParent">
                        <div className="InputLabel">
                            {l('Have you ever accepted Credit Cards?')} 
                            <div><Radio label={l("Yes")} checked={nvr_acpted_card === false} onChange={event => setnvr_acpted_card(false)}></Radio></div>
                            <div><Radio label={l("No")} checked={nvr_acpted_card === true} onChange={event => setnvr_acpted_card(true)}></Radio></div>
                        </div>
                    </div>
                </div>
            }
            {
                isApplication() &&
                <div className="Row ToggleShow" data-show={!nvr_acpted_card}>
                    <div className="FillParent">
                        <div className="InputLabel">
                            {l('Are you currently processing Credit Cards?')} 
                            <div><Radio label={l("Yes")} checked={lookng_for_new_processor === true} onChange={event => setlookng_for_new_processor(true)}></Radio></div>
                            <div><Radio label={l("No")} checked={lookng_for_new_processor === false} onChange={event => setlookng_for_new_processor(false)}></Radio></div>
                        </div>
                    </div>
                </div>
            }
            {
                isApplication() &&
                <div className="Row">
                    <div className="FillParent">
                        <div className="InputLabel">
                            {l('Have you ever been declined for processing or have been shut down by any processor?')}  <span data-for="tooltip-1" data-tip={l('Answering Yes will not disqualify you for Credit Processing. However, instant approval will not be available to avoid funds being held while getting your account setup.')} data-event="click focus" className="tooltip"><img src={question} /></span>
                            <div><Radio label={l("Yes")} checked={merch_dclnd_by_prcssr === true} onChange={event => setmerch_dclnd_by_prcssr(true)}></Radio></div>
                            <div><Radio label={l("No")} checked={merch_dclnd_by_prcssr === false} onChange={event => setmerch_dclnd_by_prcssr(false)}></Radio></div>
                        </div>
                    </div>
                </div>
            }
            {
                isApplication() &&
                <div className="Row ToggleShow" 
                    data-show={merch_dclnd_by_prcssr && lookng_for_new_processor}>
                    <div className="FillParent">
                        <div className="InputLabel">{l('What is the name of the processor?')}</div>
                        <TextInput type="text"
                            value={nm_of_prcssrs_who_dclnd_mrch}
                            onChange={e => setnm_of_prcssrs_who_dclnd_mrch(e.target.value)}
                            placeholder={l("Use comma to write multiple names")} />
                    </div>
                    <div className="FillParent">
                        <div className="InputLabel">{l('Can you briefly explain about the Shutdown?')}</div>
                        <TextInput type="text"
                            value={rsnd_for_mrch_shtdwn}
                            onChange={e => setrsnd_for_mrch_shtdwn(e.target.value)}
                            placeholder={l("Reason for shutdown")}
                        />
                    </div>
                </div>
            }
            {
                isApplication() &&
                <div className="Row MarginTop1-5x">
                    <div className="FillParent">
                        <div className="InputLabel">{l('Supported Mode of Processing Payments?')}</div>
                        <div className="Row PaymentModes">
                            <div className="innerRow">
                                <Checkbox 
                                    label={l("Online")}
                                    checked={onln_pymnt_avl} 
                                    onChange={event => setonln_pymnt_avl(event.target.checked)}></Checkbox>
                                <Checkbox 
                                    label={l("Offline")}
                                    checked={ofln_pymnt_avl} 
                                    onChange={event => setofln_pymnt_avl(event.target.checked)}></Checkbox>
                            </div>
                        </div>
                    </div>


                    <div className="FillParent">
                        <div className="ToggleShow" data-show={onln_pymnt_avl && ofln_pymnt_avl}>
                            <div className="InputLabel">{l('Estimated Percentage of Online Payments?')}</div>
                            <TextInput type="number"
                                name="onln_pymnt_prcnt" 
                                value={onln_pymnt_prcnt} 
                                onChange={e => setonln_pymnt_prcnt(e.target.value)} min="0" max="100"/>
                        </div>
                    </div>
                </div>
            }
            {
                isApplication() &&
                <div className="Row">
                    <div className="FillParent">
                        <div className="InputLabel">{l('MCC')}</div>
                        <Combobox
                            openOnFocus
                            items={getMCCMapping(props.country)}
                            autocompleteProps={{
                                title: l('Choose MCC')
                            }}
                            placeholder={l("Search by industry code or industry name")}
                            initialSelectedItem = {mcc_code}
                            onChange={selected => setmcc_code(selected)} />
                    </div>
                    <div className="FillParent">
                        <div className="InputLabel">{l('What kind of services or goods do you need processing for?')}</div>
                        <TextInput name="gds_srvcs_sold"
                            value={gds_srvcs_sold}
                            onChange={e => {
                                setgds_srvcs_sold(e.target.value);
                                FormData.gds_srvcs_sold = e.target.value;
                                MyEvent.update();
                            }}
                            placeholder={l("Please briefly explain your business")}
                        />
                    </div>
                </div>
            }
            {
                isApplication() &&
                <div className="Row">
                    <div className="FillParent">
                        <div className="InputLabel">{l('Refund Policy')}</div>
                        <Select
                            name="rfnd_polc"
                            value={rfnd_polc}
                            onChange={e => setrfnd_polc(e.target.value)}
                            >
                            <option value="(Please Select)">{l('(Please Select)')}</option>
                            <option value="No Refund">{l('No Refund')}</option>
                            <option value="Refund in 30 Days">{l('Refund in 30 Days')}</option>
                            <option value="Merchandise Exchange Only">{l('Merchandise Exchange Only')}</option>
                            <option value="Other">{l('Other')}</option>
                        </Select>
                    </div>

                    {
                        rfnd_polc === 'Other' &&
                        <div className="FillParent">
                            <div className="InputLabel">{l('Refund Policy Other, Which?')}</div>
                            <TextInput type="text"
                                name="rfnd_polc_othr"
                                value={rfnd_polc_othr}
                                onChange={e => setrfnd_polc_othr(e.target.value)}
                            />
                        </div>
                    }
                </div>
            }

            {
                !( isAffiliates() ) &&
                <div className="Row">
                    <div className="FillParent">
                        <div className="InputLabel">{l('How are the products delivered?')}</div>
                        <Select width="90%"
                            value={gds_dlvr_mode}
                            onChange={e => {
                                setgds_dlvr_mode(e.target.value);
                                FormData.gds_dlvr_mode = e.target.value;
                                MyEvent.update();
                            }}> 
                            <option value="(Please Select)">{l('(Please Select)')}</option>
                            <option value="Digitally">{l('Digitally')}</option>
                            <option value="Physical products">{l('Physical products')}</option>        
                            <option value="Webinar/Courses">{l('Webinar/Courses')}</option>
                            <option value="All">{l('All')}</option>
                        </Select>
                    </div>
                    <div className="FillParent">
                        <div className="InputLabel">{l('Is Fulfillment House Used?')}</div>
                        <Select width="90%"
                            value={ff_hous_usd}
                            disabled={gds_dlvr_mode === 'Digitally'}
                            onChange={e => setff_hous_usd(e.target.value)}>
                            <option>{l('(Please Select)')}</option>
                            <option>{l('Yes')}</option>
                            <option>{l('No')}</option>
                        </Select>
                    </div>
                </div>
            }

            <div className="Row ToggleShow" 
                data-show={ff_hous_usd === 'Yes' && gds_dlvr_mode !== "Digitally"}>
                <div className="FillParent">
                    <div className="InputLabel">{l('Fulfillment House')}</div>
                    <TextInput width="85%"
                        type="text"
                        name="ff_hous"
                        value={ff_hous}
                        onChange={e => setff_hous(e.target.value)}></TextInput>
                </div>
                <div className="FillParent">
                    <div className="InputLabel">{l('House Contact')}</div>
                    <TextInput width="85%"
                        type="text"
                        name="ff_hous_cntct"
                        value={ff_hous_cntct}
                        onChange={e => setff_hous_cntct(e.target.value)}></TextInput>
                </div>
                <div className="FillParent">
                    <div className="InputLabel">{l('Location Type')}</div>
                    <Select width="85%"
                        value={ff_hous_lctn_typ}
                        onChange={e => setff_hous_lctn_typ(e.target.value)}>
                        <option value="(Please Select)">{l('(Please Select)')}</option>
                        <option value="Retail Store Front">{l('Retail Store Front')}</option>
                        <option value="Office Building">{l('Office Building')}</option>
                        <option value="Residence">{l('Residence')}</option>
                        <option value="Industrial Building">{l('Industrial Building')}</option>
                        <option value="Trade Show">{l('Trade Show')}</option>
                    </Select>
                </div>
            </div>
            {
                !( isAffiliates() || findGetParameter('refId') === 'groovekart' || findGetParameter('refId') === 'groovekartpaid' || findGetParameter('refId') === 'groovesell' || findGetParameter('refId') === 'groovefunnel' ) && 
                <div className="Row">
                    <div className="FillParent">
                        <div className="InputLabel">{l('Select Payment Gateway Vendors:')}</div>
                        <div className="AddPaymentGatewayPopover">
                            <SelectMenu
                                isMultiSelect
                                options={pymnt_gtways_ops}
                                selected={pymnt_gtwys}
                                onSelect={selected => {
                                    let updtd_pymnt_gtwys = [...pymnt_gtwys];
                                    updtd_pymnt_gtwys.push(selected.value);
                                    setpymnt_gtwys(updtd_pymnt_gtwys);
                                }}
                                onDeselect={deselected => {
                                    let updtd_pymnt_gtwys = [...pymnt_gtwys];
                                    let dslct_idx = updtd_pymnt_gtwys.indexOf(deselected.value);
                                    updtd_pymnt_gtwys.splice(dslct_idx, 1);
                                    setpymnt_gtwys(updtd_pymnt_gtwys);
                                }}>
                                <Button>{pymnt_gtwys.length > 0 && pymnt_gtwys.join(', ') || l('Select Gateways')}</Button>
                            </SelectMenu>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
})