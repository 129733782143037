import React from 'react';
import './Header.css';
import {l} from '../../Functions';

export default function Header(){
    return (
        <div className="Position Fixed Row CenterV Header">
            <div className="BrandLogo">
                <img src="/brand_transparent_logo.png" alt="brand_logo"/>
            </div>

            {/* <div className="Action"><a 
                href="https://www.groovepay.com/apply?ref=tests&r_done=1#_3eknxun0m" 
                target="_blank" rel="noopener noreferrer">Apply</a></div> */}
            <div className="Action"><a 
                href="https://www.groovepay.com/affiliate-program" 
                target="_blank" rel="noopener noreferrer">{l('Affiliates')}</a></div>
            <div className="Action"><a 
                href="https://www.groovepay.com/contact-us" 
                target="_blank" rel="noopener noreferrer">{l('Contact Us')}</a></div>

            <div className="FillParent"></div>

{/*             <i className="social ri-facebook-fill"></i>
            <i className="social ri-twitter-fill"></i>
            <i className="social ri-linkedin-fill"></i> */}
        </div>
    );
}