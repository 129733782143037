import React, {useState, forwardRef, useImperativeHandle} from 'react';

import {Select, TextInput, TagInput, SelectMenu, Button} from 'evergreen-ui';

import FormData from '../../services/form-data-collector.service';

// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.

export default forwardRef(function SiteSurvey(props, ref){
    
    const [gds_dlvr_mode, setgds_dlvr_mode] = useState(FormData.gds_dlvr_mode);
    const [ff_hous_usd, setff_hous_usd] = useState( FormData.ff_hous_usd || false);
    const [ff_hous_lctn_typ, setff_hous_lctn_typ] = useState(FormData.ff_hous_lctn_typ);
    const [ff_hous_cntct, setff_hous_cntct] = useState(FormData.ff_hous_cntct || '');
    const [ff_hous, setff_hous] = useState(FormData.ff_hous || '');
    const [pymnt_gtwys, setpymnt_gtwys] = useState(FormData.pymnt_gtwys || []);



    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        triggerPublishData(){
            publishDataToCollector();
        }
    }));

    function publishDataToCollector(){
        FormData.gds_dlvr_mode = gds_dlvr_mode;
        FormData.pymn_stndrs_compliant = true;
        FormData.pymnt_gtwys = pymnt_gtwys;

        if(gds_dlvr_mode === 'Digitally'){
            FormData.ff_hous_usd = "";
            FormData.ff_hous_lctn_typ = "";
            FormData.ff_hous_cntct = "";
            FormData.ff_hous = "";
        }else{
            FormData.ff_hous_usd = ff_hous_usd;
            FormData.ff_hous_lctn_typ = ff_hous_lctn_typ;
            FormData.ff_hous_cntct = ff_hous_cntct;
            FormData.ff_hous = ff_hous;
        }
    }
    
    let pymnt_gtways_ops = [
        "GROOVEKART", "GROOVESELL", "Authorize.Net", 
        "NMI", "Shopify", "Woo Commerce", "Others"
    ];
    pymnt_gtways_ops = pymnt_gtways_ops.map(op => ({"label": op, "value": op}));

    return(
        <React.Fragment>
            <div className="Row">
                <div className="FillParent">
                    <div className="InputLabel">How are the products delivered?</div>
                    <Select width="90%"
                        value={gds_dlvr_mode}
                        onChange={e => setgds_dlvr_mode(e.target.value)}>
                        <option>(Please Select)</option>
                        <option>Digitally</option>
                        <option>Physical product</option>        
                        <option>Webinar/Courses</option>
                        <option>All</option>
                    </Select>
                </div>
                <div className="FillParent">
                    <div className="InputLabel">Is Fulfillment House Used?</div>
                    <Select width="90%"
                        value={ff_hous_usd}
                        disabled={gds_dlvr_mode === 'Digitally'}
                        onChange={e => setff_hous_usd(e.target.value)}>
                        <option>(Please Select)</option>
                        <option>Yes</option>
                        <option>No</option>
                    </Select>
                </div>
            </div>

            <div className="Row ToggleShow" 
                data-show={ff_hous_usd === 'Yes' && gds_dlvr_mode !== "Digitally"}>
                <div className="FillParent">
                    <div className="InputLabel">Fulfillment House</div>
                    <TextInput width="85%"
                        type="text"
                        name="ff_hous"
                        value={ff_hous}
                        onChange={e => setff_hous(e.target.value)}></TextInput>
                </div>
                <div className="FillParent">
                    <div className="InputLabel">House Contact</div>
                    <TextInput width="85%"
                        type="text"
                        name="ff_hous_cntct"
                        value={ff_hous_cntct}
                        onChange={e => setff_hous_cntct(e.target.value)}></TextInput>
                </div>
                <div className="FillParent">
                    <div className="InputLabel">Location Type</div>
                    <Select width="85%"
                        value={ff_hous_lctn_typ}
                        onChange={e => setff_hous_lctn_typ(e.target.value)}>
                        <option>(Please Select)</option>
                        <option>Retail Store Front</option>
                        <option>Office Building</option>
                        <option>Residence</option>
                        <option>Industrial Building</option>
                        <option>Trade Show</option>
                    </Select>
                </div>
                <div className="FillParent">
                    <div className="InputLabel">Location Type</div>
                    <Select width="85%"
                        value={ff_hous_lctn_typ}
                        onChange={e => setff_hous_lctn_typ(e.target.value)}>
                        <option>(Please Select)</option>
                        <option>Retail Store Front</option>
                        <option>Office Building</option>
                        <option>Residence</option>
                        <option>Industrial Building</option>
                        <option>Trade Show</option>
                    </Select>
                </div>
            </div>
            <div className="Row">
                <div className="FillParent">
                    <div className="InputLabel">Select Payment Gateway Vendors:</div>
                    <div className="AddPaymentGatewayPopover">
                        <SelectMenu
                            isMultiSelect
                            options={pymnt_gtways_ops}
                            selected={pymnt_gtwys}
                            onSelect={selected => {
                                let updtd_pymnt_gtwys = [...pymnt_gtwys];
                                updtd_pymnt_gtwys.push(selected.value);
                                setpymnt_gtwys(updtd_pymnt_gtwys);
                            }}
                            onDeselect={deselected => {
                                let updtd_pymnt_gtwys = [...pymnt_gtwys];
                                let dslct_idx = updtd_pymnt_gtwys.indexOf(deselected.value);
                                updtd_pymnt_gtwys.splice(dslct_idx, 1);
                                setpymnt_gtwys(updtd_pymnt_gtwys);
                            }}>
                            <Button>{pymnt_gtwys.length > 0 && pymnt_gtwys.join(', ') || 'Select Gateways'}</Button>
                        </SelectMenu>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
});