import React, { useState, useRef } from "react";
import "./App.css";
import "remixicon/fonts/remixicon.css";

import Header from "./components/header/Header";
import FormNavbar from "./components/form-navbar/FormNavbar";
// import ApplicationSubmit from './components/appl-submit/ApplicationSubmit';
import FormSection from "./components/form-section/FormSection";
import { Dialog, toaster, Button, Alert } from "evergreen-ui";
import {
  ApplicationForm,
  mac_os_platforms,
  chkbx_typ_flds,
} from "./appl.constants";

import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";

import Shield from "./assets/img/shield.svg";
import { Select } from "evergreen-ui";
import ReactTooltip from "react-tooltip";

import FormDataProvider from "./services/form-data-collector.service";
import MyEvent from "./MyEvent";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";

import { l } from "./Functions";

window.$ajaxPath = "https://app.groovepay.com/irisApi";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => ++value); // update the state to force render
}

function isChargebacks() {
  return window.location.pathname === "/chargebacks";
}

function isAffiliates() {
  return window.location.pathname === "/affiliates";
}

function isApplication() {
  return !(isChargebacks() || isAffiliates());
}

function inArray(needle, haystack) {
  var length = haystack.length;
  for (var i = 0; i < length; i++) {
    if (haystack[i] == needle) return true;
  }
  return false;
}

window.datas = FormDataProvider;

function isGroovePayPro() {
  const annualVolume =
    FormDataProvider.annl_vol &&
    parseInt(
      replaceAll(
        replaceAll(replaceAll(FormDataProvider.annl_vol, ",", ""), "R$", ""),
        "$",
        ""
      )
    ) >= 600000;
  const notPhysicalProduct =
    FormDataProvider.gds_dlvr_mode &&
    FormDataProvider.gds_dlvr_mode !== "(Please Select)" &&
    FormDataProvider.gds_dlvr_mode !== "Physical products";

  let proKeywords = false;

  for (const keyword of [
    "Travel",
    "Supplements",
    "CBD",
    "Adult",
    "Gun",
    "Weapon",
    "Vitamins",
    "Gambling",
    "Marketing",
    "Hosting",
    "Investment",
    "Trading",
    "Donations",
    "Donation",
    "Coaching",
  ]) {
    if (
      FormDataProvider.gds_srvcs_sold &&
      FormDataProvider.gds_srvcs_sold.includes(keyword)
    ) {
      proKeywords = true;
      break;
    }
  }

  return annualVolume || notPhysicalProduct || proKeywords;
}

function isInternationalStripe() {
  const specificCountry = inArray(FormDataProvider.bis_country, [
    "Australia",
    "Austria",
    "Belgium",
    "Bulgaria",
    "Canada",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Greece",
    "Hong Kong",
    "Hungary",
    "India",
    "Ireland",
    "Italy",
    "Japan",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malaysia",
    "Malta",
    "Mexico",
    "Netherlands",
    "New Zealand",
    "Norway",
    "Poland",
    "Portugal",
    "Romania",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    "United Kingdom",
  ]);
  return specificCountry;
}

function replaceAll(str, searchStr, replaceStr) {
  // escape regexp special characters in search string
  const pattern = searchStr.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");

  return str.replace(new RegExp(pattern, "gi"), replaceStr);
}

function findGetParameter(parameterName) {
  var result = null,
    tmp = [];
  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });

  return result;
}

function whiteLabel() {
  return findGetParameter("htjraze") ? true : false;
}

if (isApplication()) FormDataProvider.annl_vol = "240000";

if (findGetParameter("refId")) {
  FormDataProvider.referrer = findGetParameter("refId");

  if (
    findGetParameter("refId") === "groovekart" ||
    findGetParameter("refId") === "groovekartpaid" ||
    findGetParameter("refId") === "groovesell" ||
    findGetParameter("refId") === "groovefunnel"
  ) {
    FormDataProvider.pymnt_gtwys = ["GROOVEKART", "GROOVESELL"];

    if (findGetParameter("refId") === "groovekart") {
      FormDataProvider.pricing_template = 18;
      FormDataProvider.propay_tiers = "GS_FREE_485_1";

      FormDataProvider.vmd_qual_discount = "4.85";
      FormDataProvider.vmd_qual_fee = ".30";
      FormDataProvider.chargeback = "45";
      FormDataProvider.annl_vol = "300000";
      FormDataProvider.reserver_amount = "0";
      FormDataProvider.funding_hold = "0";
      FormDataProvider.bank_deposit_fee = ".30";
    }

    if (findGetParameter("refId") === "groovekartpaid") {
      FormDataProvider.pricing_template = 13;
      FormDataProvider.propay_tiers = "GK_285";

      FormDataProvider.vmd_qual_discount = "2.85";
      FormDataProvider.vmd_qual_fee = ".25";
      FormDataProvider.chargeback = "35";
      FormDataProvider.annl_vol = "300000";
      FormDataProvider.reserver_amount = "0";
      FormDataProvider.funding_hold = "0";
      FormDataProvider.bank_deposit_fee = ".30";
    }
  }
}

FormDataProvider.onln_pymnt_avl = true;
FormDataProvider.ff_hous_usd = "No";

if (findGetParameter("storename"))
  FormDataProvider.storename = findGetParameter("storename");

if (findGetParameter("refemail"))
  FormDataProvider.refemail = findGetParameter("refemail");

if (findGetParameter("uid")) FormDataProvider.uid = findGetParameter("uid");

function App({ match, location }) {
  //   console.log("App started");
  const is_mac_os = mac_os_platforms.includes(window.navigator.platform);

  var url = new URL(window.location.href);
  var getLang = url.searchParams.get("lang");

  if (getLang) window.lang = getLang;

  const [country, setCountryState] = useState(getLang || null);
  const [leadIdUplaoder, setLeadIdUplaoder] = useState(false);
  const [verificationFiles, setVerificationFiles] = useState([]);
  const forceUpdate = useForceUpdate();
  let ajaxUrl = "https://groovepay.iriscrm.com/postleads/t/";

  MyEvent.addEventListener("update", () => {
    forceUpdate();
  });

  function setCountry(target) {
    setCountryState(target);
    window.lang = target;
  }

  // In order to gain access to the child component instance,
  // you need to assign it to a `ref`, so we call `useRef()` to get one
  const childRef = useRef();

  function isAmerican() {
    return country === "us";
  }

  function isBrazilian() {
    return country === "br";
  }

  function updateActiveFormType(active_form_index) {
    setactive_form(form_types[active_form_index]);
    setform_heading(form_headings[active_form_index]);
    setform_desc(form_descriptions[active_form_index]);
    triggerActiveFormDataPublish();
  }

  function triggerActiveFormDataPublish() {
    childRef.current.triggerPublishData();
  }

  let requestedField = {
    [ApplicationForm.BIS_INFO]: {
      own_frst_nm: l("First name"),
      own_last_nm: l("Last name"),
      lgl_nm: l("Legal business name"),
      bis_email: l("Customer Service Email"),
      corp_type: l("Type of Corporation"),
      dba_adr: l("Business physical Address"),
      bil_adr: l("Billing Address"),
      cust_sup_phn: l("Customer Support Phone"),
    },
    [ApplicationForm.FIN_PROF]: {},
    [ApplicationForm.OWNR_INFO]: {},
  };

  if (isApplication()) {
    requestedField[ApplicationForm.BIS_INFO]["bis_country"] =
      l("Business country");
    requestedField[ApplicationForm.BIS_INFO]["dba_nm"] = l("DBA Name");
    requestedField[ApplicationForm.BIS_INFO]["avg_tkt"] = l("Average Ticket");
    requestedField[ApplicationForm.BIS_INFO]["hgh_tkt"] = l("Highest Ticket");

    requestedField[ApplicationForm.OWNR_INFO] = {
      ownr_frst_nm: l("Owner first name"),
      ownr_last_nm: l("Owner last name"),
      ownr_dob: l("Owner date of birth"),
      ownr_title: l("Owner title"),
      ownrshp_pcnt: l("Owner ownership percentage"),
      ownr_email: l("Owner email"),
      ownr_phn: l("Owner phone"),
      ownr_addr: l("Owner address"),
      ownr_addr_city: l("Owner city"),
      ownr_drv_lis: l("Driver license number"),
    };

    if (FormDataProvider.corp_type === "Individual") {
      requestedField[ApplicationForm.BIS_INFO]["ownr_drv_lis"] = l(
        "Driver license number"
      );
      requestedField[ApplicationForm.BIS_INFO]["ownr_dob"] = l("Date of birth");
    } else if (
      FormDataProvider.corp_type === "Company" ||
      FormDataProvider.corp_type === "Nonprofit"
    ) {
      requestedField[ApplicationForm.BIS_INFO]["fed_tax_id"] =
        l("Federal Tax ID");
    }

    if (isAmerican()) {
      requestedField[ApplicationForm.FIN_PROF]["rtng_nmbr"] = l(
        "Business routing number"
      );
      requestedField[ApplicationForm.FIN_PROF]["acc_nmbr"] = l(
        "Business account number"
      );

      if (FormDataProvider.corp_type === "Individual")
        requestedField[ApplicationForm.BIS_INFO]["ownr_ssn"] = l("SSN");
      else
        requestedField[ApplicationForm.OWNR_INFO]["ownr_ssn"] = l("Owner SSN");
    } else if (isBrazilian()) {
      requestedField[ApplicationForm.FIN_PROF]["br_banco"] = l("Banco");
      requestedField[ApplicationForm.FIN_PROF]["br_agencia"] = l("AGÊNCIA");
      requestedField[ApplicationForm.FIN_PROF]["br_cc"] = l("C/C");
      requestedField[ApplicationForm.FIN_PROF]["br_cnpj_cpf"] = l("CNPJ/CPF");
      requestedField[ApplicationForm.FIN_PROF]["br_beneficiario"] = l(
        "NOME DO BENEFICIÁRIO"
      );

      if (FormDataProvider.corp_type)
        requestedField[ApplicationForm.BIS_INFO]["cnpj"] = l("CNPJ");

      if (FormDataProvider.corp_type === "EIRELI") {
        requestedField[ApplicationForm.BIS_INFO]["ownrbr_cpf"] = l("CPF");
        requestedField[ApplicationForm.BIS_INFO]["ownrbr_rg"] = l("RG");
      } else {
        requestedField[ApplicationForm.OWNR_INFO]["ownrbr_cpf"] = l("CPF");
        requestedField[ApplicationForm.OWNR_INFO]["ownrbr_rg"] = l("RG");
      }
    } else {
      requestedField[ApplicationForm.FIN_PROF]["rtng_nmbr"] = l(
        "Business routing number"
      );
      requestedField[ApplicationForm.FIN_PROF]["acc_nmbr"] = l(
        "Business account number"
      );

      if (FormDataProvider.corp_type === "Individual")
        requestedField[ApplicationForm.BIS_INFO]["ownr_passport_number"] =
          l("Passport number");
      else
        requestedField[ApplicationForm.OWNR_INFO]["ownr_passport_number"] = l(
          "Owner Passport number"
        );
    }
  } else if (isChargebacks()) {
    requestedField[ApplicationForm.FIN_PROF]["statementdescriptor"] = l(
      "Statement Descriptor 01"
    );
    requestedField[ApplicationForm.FIN_PROF]["statementdescriptor2"] = l(
      "Statement Descriptor 02"
    );
    requestedField[ApplicationForm.FIN_PROF]["statementdescriptor3"] = l(
      "Statement Descriptor 03"
    );
    requestedField[ApplicationForm.FIN_PROF]["statementdescriptor4"] = l(
      "Statement Descriptor 04"
    );
  } else if (isAffiliates()) {
    if (
      FormDataProvider.corp_type &&
      FormDataProvider.corp_type !== "Individual"
    )
      requestedField[ApplicationForm.BIS_INFO]["fed_tax_id"] = l("EIN Number");
  }

  if (!isAffiliates()) {
    requestedField[ApplicationForm.BIS_INFO]["bis_website"] = l("Website");
  }

  function navigateNext() {
    FormDataProvider.mElement = [];
    FormDataProvider.mElementOwners = [];

    let error = "";

    for (const field of Object.keys(requestedField[active_form])) {
      // console.log(active_form);
      let missingOwnerField = false;

      if (active_form === ApplicationForm.OWNR_INFO) {
        let index = -1;
        const form = FormDataProvider.bis_ownrs;
        // console.log("🚀 ~ file: App.js ~ line 411 ~ navigateNext ~ form", form);

        if (form) {
          for (const owner of form) {
            index = index + 1;
            if (!owner[field]) {
              if (
                owner["ownr_cntry"] != "United States of America" &&
                field == "ownr_ssn"
              ) {
                continue;
              }
              missingOwnerField = true;
              if (FormDataProvider.mElementOwners[index])
                FormDataProvider.mElementOwners[index].push(field);
              else FormDataProvider.mElementOwners[index] = [field];
            }
          }
        } else {
          index = index + 1;
          missingOwnerField = true;

          if (FormDataProvider.mElementOwners[index])
            FormDataProvider.mElementOwners[index].push(field);
          else FormDataProvider.mElementOwners[index] = [field];
        }
      } else {
        if (!FormDataProvider[field]) missingOwnerField = true;

        if (missingOwnerField) FormDataProvider.mElement.push(field);
      }

      if (missingOwnerField) {
        error += error !== "" ? ", " : "";
        error += requestedField[active_form][field];
      }
    }

    if (error && !window.location.href.includes("devgk")) {
      toaster.danger(
        error +
          " " +
          (FormDataProvider.mElement.length > 1
            ? l("are missing!")
            : l("is missing!"))
      );
    } else {
      if (active_form === form_types[form_types.length - 1]) {
        if (whiteLabel()) {
          setshow_dialogWL(true);
        } else {
          fetch(
            window.$ajaxPath +
              "/getLeadId.php?mail=" +
              FormDataProvider.bis_email
          )
            .then((res) => res.json())
            .then((response) => {
              if (response && response.id) {
                toaster.danger(
                  l("An application with the same email") +
                    " (" +
                    FormDataProvider.bis_email +
                    ") " +
                    l(
                      "has been placed. Please contact us if you wish to edit your application or change your email if it's a new application."
                    )
                );
              } else {
                setshow_dialog(true);
              }
            })
            .catch((error) => {
              toaster.danger(
                l("An application with the same email") +
                  " (" +
                  FormDataProvider.bis_email +
                  ") " +
                  l(
                    "has been placed. Please contact us if you wish to edit your application or change your email if it's a new application."
                  )
              );
            });
        }
      } else {
        let curr_idx = form_types.indexOf(active_form);
        updateActiveFormType(++curr_idx);
      }
    }

    childRef.current.triggerPublishData();
  }

  function navigatePrev() {
    let curr_idx = form_types.indexOf(active_form);
    updateActiveFormType(--curr_idx);
  }

  function sendLeadToIrisWL() {
    setshow_dialogWL(false);
    fetch(
      window.$ajaxPath + "/getLeadId.php?mail=" + FormDataProvider.bis_email
    )
      .then((res) => res.json())
      .then((response) => {
        if (response && response.id) {
          toaster.danger(
            l("An application with the same email") +
              " (" +
              FormDataProvider.bis_email +
              ") " +
              l(
                "has been placed. Please contact us if you wish to edit your application or change your email if it's a new application."
              )
          );
        } else {
          setshow_dialog(true);
        }
      })
      .catch((error) => {
        toaster.danger(
          l("An application with the same email") +
            " (" +
            FormDataProvider.bis_email +
            ") " +
            l(
              "has been placed. Please contact us if you wish to edit your application or change your email if it's a new application."
            )
        );
      });
  }

  function sendLeadToIris() {
    setpublshng_to_iris(true);
    triggerActiveFormDataPublish();

    let FD = new FormData();
    for (let key in FormDataProvider) {
      FD.append(key, FormDataProvider[key]);
    }

    // handle checkbox type fields
    chkbx_typ_flds.forEach((field) => {
      let updtd_val = FD.get(field) ? 1 : 0;
      FD.set(field, updtd_val);
    });

    // handle owner tab fields for multiple owners here
    if (FormDataProvider.bis_ownrs) {
      FormDataProvider.bis_ownrs.forEach((biw_ownr, idx) => {
        let suffix = idx === 0 ? "" : "_" + (idx + 1);
        Object.keys(biw_ownr).forEach((ownrPropKey) => {
          FD.append(ownrPropKey + suffix, biw_ownr[ownrPropKey]);
        });
      });
    }

    // level 1 : country, level 2 : regular/pro, level 3 : referral
    const tokens = {
      american: {
        regular: {
          groovekart: "7f593d0a01136e18b6ebcf089e95322c",
          groovekartpaid: "95186c02008c2b96474faf67f65f7ddb",
          groovesell: "a412dfc34b0022c9dfe4e02bb52b65b4",
          groovefunnel: "a113138a51154ae509cd276547b28b6b",
          other: "2bad9453cd4319bae9c4d790a87f67a5",
          whitelabel: "d9ed81cc79b091078bff13c9ab72ff37",
        },
        pro: {
          groovekart: "20287dd553587a74942e9e2abad9c709",
          groovekartpaid: "cc624f7c8971286dfcde1b2fe80ba59d",
          groovesell: "f7845624fc143aef3709cc7ae8b7a68b",
          groovefunnel: "da999a4b79bf686d5a97039c59dceb56",
          other: "7ddead2715c60ffef5f52ecebde5dc48",
          whitelabel: "44fe78e69af32a3ae74caea3008c7bb1",
        },
      },
      brazilian: {
        regular: {
          groovekart: "016a64a7e80b2b6fc09dbfe8aeb68630",
          groovekartpaid: "b7faff2d2e89b7a881ead8243c6d6c9e",
          groovesell: "cab339baf2ea4c399990303b119cc252",
          groovefunnel: "df7a113c35b043d01ce79a4fff42aff2",
          other: "5b1e165977ea4718e376e550e994137c",
          whitelabel: "03124370358f58576db2ac3f8d14ee3a",
        },
        pro: {
          groovekart: "016a64a7e80b2b6fc09dbfe8aeb68630",
          groovekartpaid: "b7faff2d2e89b7a881ead8243c6d6c9e",
          groovesell: "cab339baf2ea4c399990303b119cc252",
          groovefunnel: "df7a113c35b043d01ce79a4fff42aff2",
          other: "5b1e165977ea4718e376e550e994137c",
          whitelabel: "03124370358f58576db2ac3f8d14ee3a",
        },
      },
      international: {
        regular: {
          groovekart: "310cc4d44ca1910738b4f85c2c067815",
          groovekartpaid: "95bbe1ad2efe45eb56a8ece4730e0b7a",
          groovesell: "2ba213845a0a064b22e86d40b8deb257",
          groovefunnel: "5a03ea0d0d167b2a8b6a3aed1a044c1a",
          other: "6b78f368083cef9e8d47a995e60d20b2",
          whitelabel: "f69ea3aaa495437424d16769adad42e1",
        },
        pro: {
          groovekart: "20287dd553587a74942e9e2abad9c709",
          groovekartpaid: "cc624f7c8971286dfcde1b2fe80ba59d",
          groovesell: "f7845624fc143aef3709cc7ae8b7a68b",
          groovefunnel: "da999a4b79bf686d5a97039c59dceb56",
          other: "7ddead2715c60ffef5f52ecebde5dc48",
          whitelabel: "44fe78e69af32a3ae74caea3008c7bb1",
        },
        stripe: {
          groovekart: "5b605adcdb1b5946757f8c1530583417",
          groovekartpaid: "c835eea15de2b26ec95ccfe4e4e380ae",
          groovesell: "a9b5a7c6bc80b8bed052bb4b8f1b17f9",
          groovefunnel: "87485b5a277a5f9547a7e51d68b5c6f1",
          other: "eef6ea43c55b609a3614a4106fc6d67d",
          whitelabel: "b06e0bcb7464a9419a18442533c48aba",
        },
      },
      chargebacks: "53c323f5d3f1311a784b0b85896dd8c2",
      affiliates: "fda41196dc75a2a52fd1b89029a5de74",
    };

    let level1 = null;
    let level2 = null;
    let level3 = null;

    if (isChargebacks()) ajaxUrl += tokens["chargebacks"];
    else if (isAffiliates()) ajaxUrl += tokens["affiliates"];
    else {
      // Step 1
      if (isAmerican()) level1 = "american";
      else if (isBrazilian()) level1 = "brazilian";
      else level1 = "international";

      if (isGroovePayPro()) level2 = "pro";
      else if (isInternationalStripe()) level2 = "stripe";
      else level2 = "regular";

      level3 =
        findGetParameter("refId") === "groovekart" ||
        findGetParameter("refId") === "groovekartpaid" ||
        findGetParameter("refId") === "groovesell" ||
        findGetParameter("refId") === "groovefunnel"
          ? findGetParameter("refId")
          : "other";

      if (whiteLabel()) level3 = "whitelabel";

      ajaxUrl += tokens[level1][level2][level3];
    }

    fetch(ajaxUrl, {
      method: "POST",
      body: FD,
    })
      .then((response) => {
        if (response && !response.ok) {
          throw new Error(l("Unexpected error while submitting application."));
        } else if (response && response.ok) {
          toaster.success(l("Application Submission Successful."));

          if (
            !isGroovePayPro() &&
            (isAmerican() || isInternationalStripe() || isBrazilian())
          )
            setLeadIdUplaoder(true);
          else
            setTimeout(() => {
              window.location.href =
                "https://app.groovepay.com/thankyou?lang=" + country;
            }, 1500);
        }
      })
      .catch((error) => {
        toaster.danger(l("Application Submission Failed. Please Retry."));
      })
      .finally(() => {
        setpublshng_to_iris(false);
        setshow_dialog(false);
      });
  }

  let form_types = [ApplicationForm.BIS_INFO];

  let form_headings = [l("About your business")];
  let form_descriptions = [l("Mention the details of your business here")];

  if (
    !(
      findGetParameter("refId") === "groovekart" ||
      findGetParameter("refId") === "groovekartpaid" ||
      findGetParameter("refId") === "groovesell" ||
      findGetParameter("refId") === "groovefunnel"
    ) &&
    isApplication()
  ) {
    // form_types.push(ApplicationForm.SITE_SURVEY)
    // form_headings.push("Site Survey")
    // form_descriptions.push("Mention the details of your business location here")
  }

  form_types.push(ApplicationForm.FIN_PROF);
  form_headings.push(
    isChargebacks()
      ? l("Payment informations")
      : l("Where should your funds be deposited?")
  );
  form_descriptions.push(
    l("Mention the financial account details of your business here")
  );

  if (
    FormDataProvider.corp_type &&
    FormDataProvider.corp_type !== "Individual" &&
    FormDataProvider.corp_type !== "EIRELI" &&
    !isAffiliates()
  ) {
    form_types.push(ApplicationForm.OWNR_INFO);
    form_headings.push("");
    form_descriptions.push("");
  }

  const [active_form, setactive_form] = useState(ApplicationForm.BIS_INFO);
  const [form_heading, setform_heading] = useState(form_headings[0]);
  const [form_desc, setform_desc] = useState(form_descriptions[0]);
  const [show_dialog, setshow_dialog] = useState(false);
  const [show_dialogWL, setshow_dialogWL] = useState(false);
  const [publshng_to_iris, setpublshng_to_iris] = useState(false);

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  // specify upload params and url for your files
  function getUploadParams(label) {
    return {
      url: window.$ajaxPath + "/iriscrm.php",
      fields: {
        mail: FormDataProvider.bis_email || null,
        label: label.id,
        label_name: label.name,
      },
    };
  }

  return (
    <Router>
      <div className="App" data-mac-os={is_mac_os}>
        {!whiteLabel() && <Header />}

        <Switch>
          <Route path="/confirm/:email" component={Uploader} />
          <Route path="/mpgpdvlbls" component={MapLabels} />
          <Route path="/thankyou">
            <div
              className="the-form"
              style={whiteLabel() ? { marginTop: 90 } : {}}
            >
              <h3 className="text-center">
                {l(
                  "We have received your information and one of our processing experts will contact you shortly via email."
                )}
              </h3>
              <h4 className="text-center">
                {l(
                  "If you have any questions, please feel free to reach out to us at applications@groovepay.com."
                )}
              </h4>
            </div>
          </Route>
          <Route path="/chargebacks">
            <div>
              <Dialog
                isShown={show_dialog}
                title={l("Submit Confirmation")}
                isConfirmLoading={publshng_to_iris}
                onConfirm={sendLeadToIris}
                onCancel={() => {
                  setshow_dialog(false);
                  setpublshng_to_iris(false);
                }}
                confirmLabel={
                  publshng_to_iris ? l("Submitting...") : l("Confirm Submit")
                }
                cancelLabel={l("Cancel")}
                hasClose={false}
                hasCancel={!publshng_to_iris}
              >
                <p>
                  {l("Are you sure you're done with the application form?")}
                </p>
              </Dialog>
              <FormNavbar
                style={whiteLabel() ? { top: 0 } : {}}
                activeSectionIndex={form_types.indexOf(active_form)}
                updateActiveFormType={updateActiveFormType}
                FormDataProvider={FormDataProvider}
                path="/chargebacks"
              />
              <div
                className="the-form"
                style={whiteLabel() ? { marginTop: 90 } : {}}
              >
                {!whiteLabel() && (
                  <h3 className="text-center">{l("Welcome to GroovePay")}</h3>
                )}
                {!whiteLabel() && (
                  <h4 className="text-center">
                    {l(
                      "Please complete the information below to sign up for Chargeback Protection Services."
                    )}
                  </h4>
                )}

                <div className="FormNavigation Row Reverse">
                  <Button
                    iconAfter="arrow-right"
                    appearance="primary"
                    onClick={() => navigateNext()}
                  >
                    {(active_form === form_types[form_types.length - 1] &&
                      l("Submit Application")) ||
                      l("Next")}
                  </Button>
                  <Button
                    iconBefore="arrow-left"
                    disabled={active_form === ApplicationForm.BIS_INFO}
                    onClick={navigatePrev}
                  >
                    {l("Prev")}
                  </Button>
                </div>

                <div>
                  <FormSection
                    bindToChildFormRef={childRef}
                    formHeading={form_heading}
                    form={active_form}
                    path="/chargebacks"
                  />
                </div>

                {/* Form Navigation */}
                <div className="FormNavigation Row Reverse">
                  <Button
                    iconAfter="arrow-right"
                    appearance="primary"
                    onClick={() => navigateNext()}
                  >
                    {(active_form === form_types[form_types.length - 1] &&
                      l("Submit Application")) ||
                      l("Next")}
                  </Button>
                  <Button
                    iconBefore="arrow-left"
                    disabled={active_form === ApplicationForm.BIS_INFO}
                    onClick={navigatePrev}
                  >
                    {l("Prev")}
                  </Button>
                </div>
              </div>
            </div>
          </Route>
          <Route path="/affiliates">
            <div>
              <Dialog
                isShown={show_dialog}
                title={l("Submit Confirmation")}
                isConfirmLoading={publshng_to_iris}
                onConfirm={sendLeadToIris}
                onCancel={() => {
                  setshow_dialog(false);
                  setpublshng_to_iris(false);
                }}
                confirmLabel={
                  publshng_to_iris ? l("Submitting...") : l("Confirm Submit")
                }
                cancelLabel={l("Cancel")}
                hasClose={false}
                hasCancel={!publshng_to_iris}
              >
                <p>
                  {l("Are you sure you're done with the application form?")}
                </p>
              </Dialog>
              <FormNavbar
                style={whiteLabel() ? { top: 0 } : {}}
                activeSectionIndex={form_types.indexOf(active_form)}
                updateActiveFormType={updateActiveFormType}
                FormDataProvider={FormDataProvider}
                path="/chargebacks"
              />
              <div
                className="the-form"
                style={whiteLabel() ? { marginTop: 90 } : {}}
              >
                {!whiteLabel() && (
                  <h3 className="text-center">{l("Welcome to GroovePay")}</h3>
                )}
                {!whiteLabel() && (
                  <h4 className="text-center">
                    {l(
                      "Please complete the information below to become a valued GroovePay Affiliate."
                    )}
                  </h4>
                )}

                <div className="FormNavigation Row Reverse">
                  <Button
                    iconAfter="arrow-right"
                    appearance="primary"
                    onClick={() => navigateNext()}
                  >
                    {(active_form === form_types[form_types.length - 1] &&
                      l("Submit Application")) ||
                      l("Next")}
                  </Button>
                  <Button
                    iconBefore="arrow-left"
                    disabled={active_form === ApplicationForm.BIS_INFO}
                    onClick={navigatePrev}
                  >
                    {l("Prev")}
                  </Button>
                </div>

                <div>
                  <FormSection
                    bindToChildFormRef={childRef}
                    formHeading={form_heading}
                    form={active_form}
                    path="/affiliates"
                  />
                </div>

                {/* Form Navigation */}
                <div className="FormNavigation Row Reverse">
                  <Button
                    iconAfter="arrow-right"
                    appearance="primary"
                    onClick={() => navigateNext()}
                  >
                    {(active_form === form_types[form_types.length - 1] &&
                      l("Submit Application")) ||
                      l("Next")}
                  </Button>
                  <Button
                    iconBefore="arrow-left"
                    disabled={active_form === ApplicationForm.BIS_INFO}
                    onClick={navigatePrev}
                  >
                    {l("Prev")}
                  </Button>
                </div>
              </div>
            </div>
          </Route>
          <Route path="/" exact>
            {(country && (
              <div>
                <Dialog
                  isShown={show_dialog}
                  title={l("Submit Application Confirmation")}
                  isConfirmLoading={publshng_to_iris}
                  onConfirm={sendLeadToIris}
                  onCancel={() => {
                    setshow_dialog(false);
                    setpublshng_to_iris(false);
                  }}
                  confirmLabel={
                    publshng_to_iris ? l("Submitting...") : l("Confirm Submit")
                  }
                  cancelLabel={l("Cancel")}
                  hasClose={false}
                  hasCancel={!publshng_to_iris}
                >
                  <p>
                    {l("Are you sure you're done with the application form?")}
                  </p>
                </Dialog>
                <Dialog
                  isShown={show_dialogWL}
                  title={l("Terms of the Merchant Processing Agreement")}
                  isConfirmLoading={publshng_to_iris}
                  onConfirm={sendLeadToIrisWL}
                  onCancel={() => {
                    setshow_dialogWL(false);
                  }}
                  confirmLabel={
                    publshng_to_iris ? l("Submitting...") : l("I agree")
                  }
                  cancelLabel={l("Cancel")}
                  hasClose={false}
                  hasCancel={!publshng_to_iris}
                >
                  <div className="termsdiv">
                    <div class="content">
                      <h5>1. DEFINITIONS AND INTERPRETATION</h5>
                      <p>
                        1.1 <b>Definitions</b> - The following terms are defined
                        for use in this Agreement:{" "}
                      </p>
                      <ul>
                        <li>
                          <b>GroovePay Credit Card Service</b> means the
                          GroovePay service which allows GroovePay to accept
                          payments for Merchant's services via credit card.
                        </li>
                        <li>
                          <b>Agreement</b> means this Merchant
                          ProcessingAgreement.
                        </li>
                        <li>
                          <b>Cardholder or End User</b> means the natural person
                          or legal entity or entities who or which has or have
                          ordered goods and/ or services from Merchant via
                          GroovePay and who or which is/ are formally and
                          officially registered with a financial institution as
                          the holder of a particular debit or credit card.
                        </li>
                        <li>
                          <b>Cardholder</b> Charge means the amount to be
                          charged to the Cardholder's account for the purchase
                          of access to the Merchant's products and services via
                          GroovePay and/or theMerchant.
                        </li>
                        <li>
                          <b>Chargeback</b> means a Card holder Charge via the
                          GroovePay Credit Card Service which the Cardholder's
                          credit card issuer identifies as being invalid or
                          non-collectible after initial acceptance on account of
                          fraud, lost, cancelled, non-issued, invalid account
                          identification, an unresolved cardholder complaint, or
                          other cause which results in the deduction of the Card
                          holder Charge from moneys otherwise payable to
                          GroovePay.
                        </li>
                        <li>
                          <b>Chargeback Alerts</b> means a notification system
                          for Merchants where, any time a customer calls their
                          issuer bank to initiate a Chargeback, the information
                          is sent to the Merchants.
                        </li>
                        <li>
                          <b>Country of Origin</b> means the country where the
                          Merchant is established.
                        </li>
                        <li>
                          <b>Excessive Chargebacks</b> means for any one of the
                          GroovePay processing account(s): (a) any number of
                          Chargebacks in excess of four (4) in any monthly
                          period; (b) any Chargebacks in any monthly period
                          which exceed a Chargeback to Transaction Ratio of one
                          percent (1%); (c) ACH returns and/or retrieval
                          requests in excess of one percent (1%) of the average
                          monthly dollar amount of transactions or returns in
                          excess of seven percent (7%) of the average monthly
                          dollar amount of transactions; (d) processing a
                          transaction above the processing limits or amounts
                          approved by use; or (e) processing any transaction
                          that violates the GroovePay or Stripe Policies or is
                          otherwise prohibited under the terms of this
                          Agreement.
                        </li>
                        <li>
                          <b>Net Revenue</b> means the Total Revenue minus
                          Refunds, Chargebacks and otherFees.
                        </li>
                        <li>
                          <b>Penalties</b> means the penalties and other charges
                          the Card Associations and/ or GroovePay may charge.
                        </li>
                        <li>
                          <b>Refund</b> means a Cardholder Charge via the
                          Services which GroovePay, Merchant, or the Cardholder
                          and GroovePay, orthe Card holder and Merchant identify
                          as being invalid or non-collectible after initial
                          acceptance on account of fraud, lost, canceled, non-
                          issued, invalid account identification, an unresolved
                          card holder complaint, or other cause which result s
                          in the deduction of the Cardholder Charge from moneys
                          otherwise payable toGroovePay.
                        </li>
                        <li>
                          <b>Retrieval</b> means a dispute initiated by a
                          customer of the Merchant with the issuing bank that is
                          considered, for purposes of this Agreement, to be
                          aChargeback.
                        </li>
                        <li>
                          <b>Reserve</b> means funds withheld from Merchant by
                          GroovePay sales in order to cover Chargebacks and
                          Refunds or Merchant indemnification obligations
                          hereunder.
                        </li>
                        <li>
                          <b>Service Fees</b> means the charges, fees and prices
                          for the Services as further set out in Section
                          "Fees/Costs" to this Agreement.
                        </li>
                        <li>
                          <b>Software</b> means software and related
                          documentation provided by GroovePay to Merchant in
                          connection with the Service.
                        </li>
                        <li>
                          <b>Total Revenue</b> means Merchant's revenues for the
                          applicable service, before the deduction of applicable
                          Reserve, Service Fees, and any other charges or
                          obligations.
                        </li>
                        <li>
                          <b>Trademarks</b> means all trademarks and logos of
                          Groove Digital or GroovePay or the Groove Brand that
                          exist now or in the future, both registered and
                          non-registered, all as may be specifiedby GroovePay
                          from time to time.
                        </li>
                        <li>
                          <b>USD</b> means the currency or dollar of United
                          States of America.
                        </li>
                        <li>
                          <b>Bank</b> means the acquiring bank.
                        </li>
                        <li>
                          <b>Card Brand</b> means the brand represented on the
                          credit card; as an example, "Visa" would be a credit
                          cardbrand
                        </li>
                      </ul>
                      <p>
                        1.2. <b>Interpretation</b>
                      </p>
                      <ul>
                        <li>
                          Headings contained in this Agreement are for
                          convenience and reference only and in no way define,
                          describe, extend or limit the scope of intent of this
                          Agreement, nor the intent of any provision hereof
                        </li>
                        <li>
                          Any reference in this Agreement to a paragraph or a
                          schedule is a reference to a paragraph or schedule of
                          this Agreement
                        </li>
                        <li>
                          Any reference to a statute or statutory provision
                          shall be construed as a reference to the same as from
                          time to time amended, consolidated,modified, attended,
                          re-enacted or replaced.{" "}
                        </li>
                      </ul>
                      <h5>2. SUBJECT OF THE AGREEMENT.</h5>
                      <p>
                        1.1. In consideration of the service fees payable by
                        Merchant and subject to Merchant complying in full with
                        its obligations under the terms of the Agreement,
                        GroovePay agrees to provide the Services upon the terms
                        of and subject to the conditions contained in this
                        Agreement.
                      </p>
                      <p>
                        2.2. Merchant appoints Groovepay as its agent for the
                        purpose of receiving, holding, and settling payments to
                        Merchant pursuant to this Agreement. Merchant agrees
                        that a payment received by GroovePay, on Merchant's
                        behalf, satisfies the customer's obligation to pay
                        Merchant, regardless of whether Groovepay settles such
                        payment with Mercha
                      </p>
                      <h5>3. FEES AND RESERVE</h5>
                      <p>
                        3.1. <b>Fees for Credit Card Service.</b> The Service
                        Fees for Services shall be equal to a percentage of the
                        gross processing during the relevant billing period, as
                        detailed in Section "Fees/Costs" of this Agreement. All
                        rates and fees are in U.S. dollars. Where Merchant may
                        become subject to any increase in rates or fees,
                        GroovePay will provide thirty (30) days advance notice
                        to Merchant of such change in fees. GroovePay may
                        provide notice of a change in fees by posting updated
                        pricing or terms on its website or by sending an email
                        communication to Merchant. By continuing to access and
                        use the Services, Merchant agrees to such changes in
                        fees. Merchant may incur price increases should Merchant
                        request changes to the Services, and Merchant agrees to
                        pay the increased fee starting on the dates of
                        confirmation of such change in Services. If Merchant
                        wishes to dispute payment to, or collection by GroovePay
                        of any fee or cost, Merchant must provide written notice
                        of such dispute within forty-five (45) days after
                        Merchant received a statement containing such fee or
                        cost, as described in Section 6 below.
                      </p>
                      <p>
                        3.2.{" "}
                        <b>
                          Penalties and Other Charges Imposed by Card
                          Association.
                        </b>{" "}
                        Merchant shall be responsible for any and all fees
                        and/or fines imposed upon GroovePay by the bank or card
                        brand that is related to processing Merchant's credit
                        card transactions. Moreover, GroovePay shall be under no
                        obligation to share any documentation with regards to
                        the fees and/or fines received from the bank or the card
                        brands it deems to be confidential.
                      </p>
                      <p>
                        3.3.{" "}
                        <b>
                          Fees for Credit Card Services Chargebacks and
                          Chargeback Alerts.
                        </b>{" "}
                        Merchant shall be charged per Alerts and/or Chargeback
                        and/or Retrieval processed against the Merchant's
                        account. Fees assessed against Merchant hereunder, if
                        any, shall be applied immediately. Chargebacks and/or
                        Retrievals are calculated against the total aggregate
                        transactions that have been processed by GroovePay for
                        the account of Merchant during a period of one (1)
                        calendar month. If GroovePay, in its sole discretion,
                        feels that the chargeback or retrieval levels are deemed
                        Excessive Chargebacks, this will constitute cause for
                        GroovePay to terminate this Agreement and close
                        Merchant's account with funds held for one hundred and
                        eighty (180) days to five hundred and forty days (540)
                        from the last transaction depending on the service
                        provided. After which time, the balance in the account
                        less Chargebacks, Retrievals, penalties, fines, Service
                        Fees/Costs and any other fees will be returned.
                        Moreover, Merchant agrees that it is absolutely
                        prohibited to suggest, imply, propose, advertise,
                        indicate, express, promote, advise or state to any of
                        its customers to Chargeback or initiate a Retrieval for
                        the products or services offered by Merchant.
                      </p>
                      <p>
                        3.4.{" "}
                        <b>Reserve for GroovePay Credit Card Processing.</b>{" "}
                        GroovePay may withhold a percentage of Merchant's Total
                        Revenues for GroovePay Credit Card processing for a
                        period as set forth in Section " Fees/Costs" of this
                        Agreement (which may be updated from time to time, at
                        the sole discretion of GroovePay), as Reserve to
                        beapplied towards Chargeback Alerts, Chargebacks,
                        Retrievals, Refunds, Fees and finesfor GroovePay
                        services. Reserve funds may be held for one hundred and
                        eighty (180) days to five hundred and forty (540) days
                        from the last transaction depending on the service
                        provided. After which time, the balance in the account
                        less Chargeback Alerts, Chargebacks, Retrievals,
                        penalties, fines, Service Fees/Costs and any other fees
                        will be returned. GroovePay shall have the right
                        anytime, in its sole discretion, to hold funds and/or
                        adjust the amount held and holdback period as is deemed
                        necessary as security against future Chargeback Alerts,
                        Chargebacks, Retrievals, Refunds, Disputes or
                        indemnification obligations of Merchant hereunder.
                        Reserves will be returned to Merchant subject to
                        expiration and resolution of all claims and issues in
                        relation thereto. In addition, if GroovePay for any
                        reason suspects or has concern that it may sustain
                        losses as a result of Merchant's account, Merchant
                        agrees and gives GroovePay the right to automatically
                        ACH debit Merchant's bank account in advance for the
                        amount of potential losses GroovePay may sustain as
                        security. GroovePay may hold the funds as reserve until
                        it has determined, in its sole discretion that
                        Merchant's account is secure and GroovePay will not
                        sustain losses as a result. GroovePay also reserves the
                        right to refund all fraud reported or fraud suspected
                        transactions or transactions that GroovePay, in their
                        sole discretion, feel may result in a chargeback and all
                        associated transactions that are reported by the banks,
                        or on the TMF, or on the MATCH, or on the TC40 and MC
                        safe fraud lists.
                      </p>
                      <p>
                        3.5. <b>Excessive Chargebacks.</b> Excessive Chargebacks
                        are a breach of the Agreement and cause for suspension
                        of Services, termination of the Agreement, and
                        assessment of an Excessive Chargeback Fee. Merchant
                        authorizes GroovePay, upon the occurrence of an
                        Excessive Chareback as defined in this Agreement, to
                        take additional actions it may deem necessary,
                        including, but not limited to, termination orsuspension
                        of Services or creation or maintenance of a Reserve
                        Account in accordance with Section 3.4. Merchant
                        acknowledges and agrees that GroovePay may be required
                        to report any Excessive Chargebacks to law enforcement,
                        or one or more card or payment associations for
                        inclusion into VISA Terminated Merchant File, MasterCard
                        MATCH, or any other prohibited merchant list in the
                        event of fraud, Chargebacks, Excessive Chargebacks, or
                        upon termination of access to Services. Merchant
                        acknowledges and agrees to such reporting and waives any
                        claims which Merchant may raise against GroovePay as a
                        result of such reporting. GroovePay may, at its sole
                        direction, report incidents of fraud, counterfeit paper,
                        unauthorized transactions, Chargebacks, Excessive
                        Chargebacks, or highly suspect activity to law
                        enforcement and any other third party, including, but
                        not limited to, the card or payment networks.
                      </p>
                      <p>
                        3.6. <b>Credit Card Services.</b> Merchant payouts are
                        calculated based on the "Settlement Period" as set forth
                        in Section "Fees/Costs" of this Agreement. Payment shall
                        be delivered to Merchant as promptly as is practicable
                        after processing, subject to GroovePay's rights to
                        offset and holdback as set forth in this Agreement. The
                        payment due to Merchant is equal to the sum of
                        Merchant's Total Revenues during the specified time
                        period along with any Reserved due to be released LESS:
                      </p>
                      <ul>
                        <li>
                          the sum of all Chargeback Alerts and/Or Chargebacks /
                          Retrievals processed during the period{" "}
                        </li>
                        <li>
                          the sum of all Refunds processed and requested during
                          the Settlement Period, the applicable Reserve, the
                          applicable Service Fee(s); and
                        </li>
                        <li>
                          all taxes, Penalties and other items reimbursable
                          hereunder or otherwise occurring during the period
                          including but not limited to any indemnification
                          obligations, offsets and holdbacks as determined by
                          GroovePay.
                        </li>
                      </ul>
                      <p>
                        3.7.{" "}
                        <b>Payment by Bank Wire Transfer or by Bank ACH.</b>{" "}
                        There will be a fee for each wire transfer of ACH
                        transfer made on the Merchant's account. Any outstanding
                        balanced not credited to merchant shall roll over to
                        Merchant's next billing cycle if appropriate. Merchant
                        authorizes GroovePay to deposit amounts owed Merchant by
                        initiating credit entries to Merchant's financial
                        institution submitted to GroovePay by Merchant. Merchant
                        further authorizes Merchant's financial institution to
                        accept and credit any entries indicated by GroovePay to
                        Merchant's account. In the event that GroovePay deposits
                        funds erroneously into Merchant's account, including
                        payment of transactions which are later Chargebacks,
                        Retrievals or otherwise contested, Merchant authorizes
                        GroovePay to debit Merchant's account for an amount not
                        to exceed the original amount of the erroneous credit,
                        and Merchant shall be personally liable for any amounts
                        that are not available for debit. This authorization is
                        to remain in full force and effect until GroovePay and
                        Merchant's financial institution have received written
                        notice from Merchant of its termination in such time and
                        manner as to afford GroovePay and Merchant's financial
                        institution reasonable opportunity to act on such notice
                        and until all accounts between Merchant and GroovePay
                        are settled, in GroovePay's discretion
                      </p>
                      <p>
                        3.8. <b>Limits.</b> GroovePay may establish processing
                        limits and assign a maximum dollar amount per
                        transaction and/or an aggregate maximum dollar amount
                        per calendar month that Merchant may process using the
                        Services. Merchant has the authority to request an
                        increase in the limit by submitting a written request to
                        GroovePay, but the increase shall be at the sole
                        discretion of GroovePay and GroovePay reserves the right
                        to reverse and/or decrease any maximum amounts
                        previously authorized. An increase in limits granted by
                        GroovePay may be conditional upon the establishment of a
                        Reserve Account as described in Section 3.4.{" "}
                      </p>
                      <p>
                        3.9.{" "}
                        <b>
                          Payment to Merchant or owner or parent company only.
                        </b>{" "}
                        GroovePay will only direct payments to Merchant, the
                        owner or parent company of the Merchant, or the
                        Merchant's registered fictitious name and accounts held
                        in the name of any such party
                      </p>
                      <h5>4. END USER INFORMATION.</h5>
                      <p>
                        4.1. Merchant understands that any information accepted
                        by GroovePay from Merchants ' end users is the property
                        of GroovePay, and shall remain the property of GroovePay
                        upon cancellation of this Agreement.
                      </p>
                      <h5>5. REFUND POLICY.</h5>
                      <p>
                        5.1. GroovePay will use commercially reasonable efforts
                        to direct inquiring and complaining Cardholders to
                        utilize the cardholder support services offered by
                        Merchant in order to resolve all disputes and
                        complaints. GroovePay, however, reserves the right to
                        issue a Refund without the knowledge or consent of
                        Merchant in any case that it deems appropriate. Such
                        determination will be binding on Merchant and the Refund
                        debited from this account. Any transaction deemed
                        fraudulent by GroovePay, will be addressed by the
                        GroovePay cardholder department, and if not verified as
                        valid to GroovePay's satisfaction the transaction will
                        be cancelled and refunded. Moreover, GroovePay will
                        Refund any transaction reported by the card brands or
                        the banks to avoid Chargebacks. If GroovePay is
                        supplying the Merchant with customer support for the
                        end-user, GroovePay shall have the right to refund any
                        transaction in its sole direction.
                      </p>
                      <h5>6. BILLING ERRORS.</h5>
                      <p>
                        6.1. If Merchant suspects an error in a payment
                        transaction statement, Merchant must notify GroovePay in
                        writing of the billing error. Merchant must include its:
                        (a) name, (b) business name, (c) bank account number,
                        (d) the dollar amount of the asserted error, and (e) a
                        summary of the asserted billing error and why Merchant
                        believes and error exists. GroovePay must receive
                        written notice within thirty (30) days after Merchant
                        received a statement containing the asserted error. If
                        Merchant does not notify GroovePay within thirty (30)
                        days of the billing error, Merchant is deemed to have
                        accepted the fees and charges as set forth in the
                        applicable statement.
                      </p>
                      <h5>7. INTELLECTUAL PROPERTY.</h5>
                      <p>
                        7.1 GroovePay hereby grants Merchant a royalty-free,
                        non-transferable and non-exclusive right for the term of
                        this Agreement to use the Trademarks on its website(s)
                        and in any off-line promotional materials solely in
                        order to indicate that it makes use of the Services.
                        Merchant shall use such Trademarks in accordance with
                        GroovePay's directions for the use of such Trademarks.
                        Merchant does not have a right of sub-license. GroovePay
                        may apply limitations to the right granted to Merchant
                        under this paragraph at any time and at its sole
                        discretion. Merchant hereby grants GroovePay and its
                        affiliated companies an irrevocable, royalty free and
                        non-exclusive right for the term of this Agreement to
                        use its trademark and logos on their website s and in
                        off-line publications for promotional purposes. When
                        using the Trademarks, Merchant will ensure that no
                        composite marks are created with its own trademarks
                        and/or logos. Merchant acknowledges that its use of the
                        Trademarks does not create for itself any rights in the
                        Trademarks other than those explicitly granted in this
                        Agreement. All proprietary rights in the equipment,
                        software (such as interfaces) and other materials used
                        or made available by GroovePay in the performance of
                        this Agreement, whether or not supplied to Merchant,
                        shall remain with GroovePay or its licensors. Merchant
                        shall only acquire such right of use as is explicitly
                        granted hereunder or otherwise. Upon termination of this
                        Agreement, Merchant will immediately with draw any
                        reference to GroovePay from its website(s) and will
                        cease the use of the Trademarks.
                      </p>
                      <h5>8. PERSONAL GUARANTEE.</h5>
                      <p>
                        8.1. Merchant shall procure a personal guarantee by its
                        owners and/or managers and/or parent companies and/or
                        their owners in favor of GroovePay substantially in the
                        form of Appendix 1, in GroovePay sole discretion.
                      </p>
                      <h5>9. DEFAULT.</h5>
                      <p>
                        9.1. In the event Merchant defaults in any provision or
                        fails to perform pursuant to this Agreement, GroovePay
                        shall be entitled to damages, costs and attorney's fees
                        from Merchant.
                      </p>
                      <h5>10. SURVIVAL OF CLAIMS.</h5>
                      <p>
                        10.1. Any claim arising out of or related to this
                        Agreement must be brought no later than one year after
                        it has accrued
                      </p>
                      <h5>11. INVALID PROVISIONS.</h5>
                      <p>
                        11.1. The invalidity or non-enforceability of any
                        provision of this Agreement, as so determined by a court
                        of competent jurisdiction, shall not affect the other
                        provisions hereof, and in any such occasion this
                        Agreement shall be construed in all respects as if such
                        invalid or non-enforceable provision were omitted or, as
                        the case may be, such provision shall be modified to the
                        minimum extent necessary to make it legal, valid and
                        enforceable.
                      </p>
                      <h5>12. CHOICE OF LAW/VENUE.</h5>
                      <p>
                        12.1. For purposes of convenience, this Agreement shall
                        be construed and enforced in accordance with the laws of
                        the State of Florida, in the United States, andthe
                        exclusive venue for any action, dispute or proceeding
                        withrespectto thisAgreementshallbeinBroward County,
                        Florida.
                      </p>
                      <h5>13. AMENDMENTS AND MODIFICATIONS.</h5>
                      <p>
                        13.1. GroovePay reserves the right to amend this
                        Agreement at any time and such amendments or changes
                        shall be effective when posted on the Merchant Portal or
                        when GroovePay notifies Merchant by other means.
                        Merchant's continued use of the Services indicates
                        acceptance and agreement to such changes{" "}
                      </p>
                      <h5>NOTICES</h5>
                      <p>
                        13.2. Any and all notices to GroovePay, or other
                        communications under or with respect to this Agreement
                        to GroovePay shall be in writing, and shall be delivered
                        by hand; e-mail; mailed post age pre-paid, either by
                        registered or certified mail, return receipt requested;
                        or by overnight courier to the following address:
                        <br />
                        GroovePay, LLC.
                        <br />
                        200 E Palmetto Park Road Suite 107,
                        <br />
                        Boca Raton, FL 33432
                      </p>
                      <p>
                        13.3. Notices to Merchant shall be made to the
                        administrative e-mail provided by Merchant on enrollment
                        with GroovePay and be deemed delivered when sent.
                      </p>
                      <h5>14. SURVIVAL OF OBLIGATIONS</h5>
                      <p>
                        14.1. The rights and obligations of the parties
                        hereunder which by their nature would continue beyond
                        the termination or cancellation of this Agreement
                        (including, without limitation, those relating to
                        confidentiality, payment of charges and limitations of
                        liability) shall survive any termination or cancellation
                        of thisAgreement.
                      </p>
                      <h5>15. TRANSFER ANDASSIGNMENT.</h5>
                      <p>
                        15.1. Merchant may not sell, assign or transfer any of
                        its rights or obligations under this Agreement without
                        the prior written consent of GroovePay
                      </p>
                      <h5>16. TAXES.</h5>
                      <p>
                        16.1. Merchant is responsible for all taxes and other
                        charges imposed by any governmental authority in
                        connection with the use of the Services. Merchant is
                        solely responsible for determining any and all taxes
                        assessed or required to be collected, and/or paid by
                        Merchant in connection with Merchant's use of the
                        Services.
                      </p>
                      <h5>17. MERCHANT INSOLVENCY; BANKRUPTCY.</h5>
                      <p>
                        17.1. Merchant must immediately notify GroovePay of its
                        insolvency or of any bankruptcy, receivership,
                        assignment for the benefit of creditors, or similar
                        action initiated by or against Merchant, whether or not
                        that action is under court supervision. GroovePay has
                        the right to terminate the Agreement upon commencement
                        of any such action or upon the insolvency of Merchant,
                        unless such termination would be deemed to violate
                        applicable law.
                      </p>
                      <h5>18. AUTHORIZATION; ENTIREAGREEMENT.</h5>
                      <p>
                        18.1. The persons signing or otherwise accepting this
                        Agreement on behalf of Merchant represent and warrant
                        that they have the authority to enter into this
                        Agreement on behalf of Merchant. This Agreement contains
                        the entire agreement of the parties and supersedes any
                        other agreements (written or oral), instruments or
                        writings as to its subject matter.
                      </p>
                      <h5>19. INDEMNITY</h5>
                      <p>
                        19.1. Merchant agrees to indemnify and hold GroovePay
                        and its affiliates and Suppliers harmless from any and
                        all claims, liability, and expenses, including
                        reasonable attorneys’ fees and costs, arising out of
                        Merchant's use of the Services or breach of this
                        Agreement (collectively referred to as “Claims”).
                        GroovePay reserves the right, in its sole discretion and
                        at its own expense, to assume the exclusive defense and
                        control of any Claims. Merchant agrees to reasonably
                        cooperate as requested by GroovePay in the defense of
                        any Claims.
                      </p>
                      <h5>20. LIMITATION OF LIABILITY.</h5>
                      <p>
                        20.1. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
                        THE ENTIRE LIABILITY OF GROOVEPAY FOR ALL CLAIMS
                        RELATING TO THIS AGREEMENT SHALL BE LIMITED TO THE
                        AMOUNT MERCHANT PAID FOR THE SERVICES DURING THE TWELVE
                        (12) MONTHS PRIOR TO SUCH CLAIM. SUBJECT TO APPLICABLE
                        LAW. GROOVEPAY IS NOT LIABLE FOR ANY OF THE FOLLOWING:
                        (A) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR
                        CONSEQUENTIAL DAMAGES; AND (B) DAMAGES RELATING TO
                        FAILURES OF TELECOMMUNICATIONS, THE INTERNET, ELECTRONIC
                        COMMUNICATIONS, CORRUPTION, SECURITY, LOSS OR THEFT OF
                        DATA, VIRUSES, SPYWARE, LOSS OF BUSINESS, REVENUE,
                        PROFITS OR INVESTMENT, OR USE OF SOFTWARE OR HARDWARE
                        THAT DOES NOT MEET GROOVEPAY SYSTEM REQUIREMENTS. THIS
                        AGREEMENT SETS FORTH THE ENTIRE LIABILITY OF GROOVEPAY
                        AND ITS AFFILIATES AND SUPPLIERS, AND MERCHANT'S
                        EXCLUSIVE REMEDY WITH RESPECT TO THE SERVICES AND ITS
                        USE.
                      </p>
                      <h5>21. COMPLIANCE WITH LAW.</h5>
                      <p>
                        21.1. In connection with the use of Services, Merchant
                        agrees to comply with all laws and regulations
                        applicable to Merchant, Merchant's business, and the
                        Services. Merchant further agrees to use Services in a
                        manner that are consistent with all rules and operating
                        regulations issued from time to time by: the credit card
                        networks (i.e. VISA, MasterCard, American Express, Apple
                        Pay, etc.); the Payment Card Industry, including but not
                        limited to the Payment Card Industry Data Security
                        Standards (“PCI DSS”) and Payment Application Data
                        Security Standard (“PA-DSS”); and the National Automated
                        Clearing House Association (“NACHA”), which applies to
                        transactions processed using Automatic Clearing House
                        (“ACH”) (collectively, “Rules”).
                      </p>
                      <h5>22. ADDITIONAL TERMS.</h5>
                      <p>
                        22.1. Payment processing services for Merchant on
                        GroovePay are provided by Stripe and are subject to the{" "}
                        <a href="https://stripe.com/connect-account/legal">
                          Stripe Connected Account
                        </a>{" "}
                        Agreement, which includes the{" "}
                        <a href="https://stripe.com/legal">
                          Stripe Terms of Service
                        </a>{" "}
                        (collectively, the “Stripe Services Agreement”). By
                        agreeing to this agreement or continuing to operate as a
                        Merchant on GroovePay, Merchant agrees to be bound by
                        the Stripe Services Agreement, as the same may be
                        modified by Stripe from time to time. As a condition of
                        GroovePay enabling payment processing services through
                        Stripe, Merchant agrees to provide GroovePay accurate
                        and complete information about Merchant and its
                        business, and Merchant authorizes GroovePay to share it
                        and transaction information related to Merchant's use of
                        the payment processing services provided by Stripe.
                      </p>
                    </div>
                  </div>
                  <p>
                    {l(
                      "I have read and agree to the terms of the Merchant Processing Agreement."
                    )}
                  </p>
                </Dialog>
                <Dialog
                  isShown={leadIdUplaoder}
                  title={l("Upload verification files")}
                  // isConfirmLoading={publshng_to_iris}
                  // onConfirm={sendLeadToIris}
                  onCancel={() => {
                    setLeadIdUplaoder(false);
                  }}
                  hasClose={false}
                  confirmLabel={l("Finish")}
                  cancelLabel={l("Cancel")}
                  onConfirm={() => {
                    var status = null;

                    if (isInternationalStripe()) {
                      if (inArray("passport_front", verificationFiles))
                        status = 6;
                      // Stripe International - New applications
                      else status = 7; // Stripe International - Missing passport
                    } else {
                      if (inArray("drivers_license", verificationFiles))
                        status = 4;
                      // Send to underwriting profac
                      else status = 5; // Drivers License Missing
                    }

                    if (status && !isBrazilian())
                      fetch(
                        window.$ajaxPath +
                          "/updatelead.php?st=" +
                          status +
                          "&mail=" +
                          FormDataProvider.bis_email
                      );

                    setLeadIdUplaoder(false);

                    setTimeout(() => {
                      window.location.href =
                        "https://app.groovepay.com/thankyou?lang=" + country;
                    }, 500);
                  }}
                >
                  <p>
                    {l(
                      "To expedite your application and for security purposes, please upload the document."
                    )}
                  </p>
                  {!isBrazilian() && (
                    <div
                      style={{
                        width: "50%",
                        padding: 20,
                        boxSizing: "border-box",
                        float: "left",
                      }}
                    >
                      <Dropzone
                        getUploadParams={({ meta }) =>
                          getUploadParams({
                            id: isInternationalStripe() ? 57 : 12,
                            name: isInternationalStripe()
                              ? l("Passport front")
                              : l("Drivers License"),
                          })
                        }
                        multiple={false}
                        accept=".pdf,.doc,image/*"
                        inputContent={
                          isInternationalStripe()
                            ? l("Passport front")
                            : l("Drivers License")
                        }
                        onChangeStatus={(fileWithMeta, status) => {
                          if (status === "done") {
                            if (
                              fileWithMeta.xhr &&
                              fileWithMeta.xhr.responseText == "1"
                            ) {
                              if (isInternationalStripe())
                                verificationFiles.push("passport_front");
                              else verificationFiles.push("drivers_license");
                            } else fileWithMeta.remove();
                          }
                        }}
                      />
                    </div>
                  )}
                  {isBrazilian() && (
                    <div
                      style={{
                        width: "100%",
                        padding: 20,
                        boxSizing: "border-box",
                        float: "left",
                      }}
                    >
                      <Dropzone
                        getUploadParams={({ meta }) =>
                          getUploadParams({
                            id: 84,
                            name: l("Contrato social"),
                          })
                        }
                        multiple={false}
                        accept=".pdf,.doc,image/*"
                        inputContent={l(
                          "Uma cópia atualizada do seu contrato social"
                        )}
                        onChangeStatus={(fileWithMeta, status) => {
                          if (status === "done") {
                            forceUpdate();

                            if (
                              fileWithMeta.xhr &&
                              fileWithMeta.xhr.responseText == "1"
                            )
                              verificationFiles.push("contrato_social");
                            else fileWithMeta.remove();
                          }
                        }}
                      />
                    </div>
                  )}
                  {isBrazilian() && (
                    <div
                      style={{
                        width: "100%",
                        padding: 20,
                        boxSizing: "border-box",
                        float: "left",
                      }}
                    >
                      <Dropzone
                        getUploadParams={({ meta }) =>
                          getUploadParams({
                            id: 85,
                            name: l("Sua conta bancária"),
                          })
                        }
                        multiple={false}
                        accept=".pdf,.doc,image/*"
                        inputContent={l(
                          "Um comprovante atualizado da sua conta bancária onde aparece visivelmente todos os dados acima de até 3 meses atrás."
                        )}
                        onChangeStatus={(fileWithMeta, status) => {
                          if (status === "done") {
                            forceUpdate();

                            if (
                              fileWithMeta.xhr &&
                              fileWithMeta.xhr.responseText == "1"
                            )
                              verificationFiles.push("sua_conta_bancaria");
                            else fileWithMeta.remove();
                          }
                        }}
                      />
                    </div>
                  )}
                  {isBrazilian() && (
                    <div
                      style={{
                        width: "100%",
                        padding: 20,
                        boxSizing: "border-box",
                        float: "left",
                      }}
                    >
                      <Dropzone
                        getUploadParams={({ meta }) =>
                          getUploadParams({
                            id: 86,
                            name: l("Documento valido"),
                          })
                        }
                        multiple={false}
                        accept=".pdf,.doc,image/*"
                        inputContent={l(
                          "Uma cópia frente e verso de um documento válido (CNH, CPF e/ou RG)"
                        )}
                        onChangeStatus={(fileWithMeta, status) => {
                          if (status === "done") {
                            forceUpdate();

                            if (
                              fileWithMeta.xhr &&
                              fileWithMeta.xhr.responseText == "1"
                            )
                              verificationFiles.push("documento_valido");
                            else fileWithMeta.remove();
                          }
                        }}
                      />
                    </div>
                  )}
                </Dialog>
                <FormNavbar
                  style={whiteLabel() ? { top: 0 } : {}}
                  activeSectionIndex={form_types.indexOf(active_form)}
                  updateActiveFormType={updateActiveFormType}
                  FormDataProvider={FormDataProvider}
                />
                <div
                  className="the-form"
                  style={whiteLabel() ? { marginTop: 90 } : {}}
                >
                  {!whiteLabel() && (
                    <h3 className="text-center">
                      {((isAmerican() || isBrazilian()) &&
                        l("Welcome to GroovePay")) ||
                        l("Welcome to GroovePay International")}
                    </h3>
                  )}
                  {!whiteLabel() && (
                    <h4 className="text-center">
                      {((isAmerican() || isBrazilian()) &&
                        l(
                          "Please complete the information below to start the pre-approval process."
                        )) ||
                        l(
                          "Caution: Due to international banking regulations, GroovePay does not support all countries, and sometimes works with partnering banks. Please complete a quick survey so that we can determine if GroovePay is supported in your country."
                        )}
                    </h4>
                  )}

                  {isBrazilian() && (
                    <Alert
                      style={{ marginTop: 20 }}
                      intent="none"
                      title="Aviso: GroovePay Brasil ainda está em fase BETA. Por favor conclua o pequeno questionário abaixo para que possamos determinar se podemos lhe atender neste momento com uma conta BETA do GroovePay Brasil para você começar a processar suas vendas conosco no Groove."
                    />
                  )}

                  <div className="FormNavigation Row Reverse">
                    <Button
                      iconAfter="arrow-right"
                      appearance="primary"
                      // disabled={active_form === ApplicationForm.OWNR_INFO}
                      onClick={() => navigateNext()}
                    >
                      {(active_form === form_types[form_types.length - 1] &&
                        l("Submit Application")) ||
                        (isGroovePayPro() && l("Continue to GroovePay Pro")) ||
                        l("Next")}
                    </Button>

                    <Button
                      iconBefore="arrow-left"
                      disabled={active_form === ApplicationForm.BIS_INFO}
                      onClick={navigatePrev}
                    >
                      {l("Prev")}
                    </Button>
                  </div>

                  <div>
                    <FormSection
                      bindToChildFormRef={childRef}
                      formHeading={form_heading}
                      country={country}
                      setCountry={setCountry}
                      form={active_form}
                    />
                  </div>

                  {/* Form Navigation */}
                  <div className="FormNavigation Row Reverse">
                    <Button
                      iconAfter="arrow-right"
                      appearance="primary"
                      // disabled={active_form === ApplicationForm.OWNR_INFO}
                      onClick={() => navigateNext()}
                    >
                      {(active_form === form_types[form_types.length - 1] &&
                        l("Submit Application")) ||
                        (isGroovePayPro() && l("Continue to GroovePay Pro")) ||
                        l("Next")}
                    </Button>
                    <Button
                      iconBefore="arrow-left"
                      disabled={active_form === ApplicationForm.BIS_INFO}
                      onClick={navigatePrev}
                    >
                      {l("Prev")}
                    </Button>
                  </div>
                </div>
              </div>
            )) || (
              <div className="popup-container">
                <div className="popup">
                  <h1>{l("Where is your business located?")}</h1>
                  <ul>
                    <li
                      onClick={() => {
                        setCountry("us");
                        FormDataProvider.bis_country =
                          "United States of America";
                      }}
                    >
                      {l("United States")}
                    </li>
                    <li
                      onClick={() => {
                        setCountry("br");
                        FormDataProvider.bis_country = "Brasil";
                      }}
                    >
                      {l("Brazil")}
                    </li>
                    {/* <li
                      onClick={() => {
                        setCountry("other");
                      }}
                    >
                      {l("Other")}
                    </li> */}
                  </ul>
                </div>
              </div>
            )}
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
class Uploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: [],
      dbaname: "",
      isProcessing: false,
      fields: [],
      fieldMaps: [],
    };

    this.fieldCount = 0;
    this.uploadedFieldCount = 0;
    this.fieldDatas = [];
  }

  // specify upload params and url for your files
  getUploadParams = (label) => {
    return {
      url: window.$ajaxPath + "/iriscrm.php",
      fields: {
        mail: this.props.match.params.email || null,
        label: label.dlid,
        label_name: label.ftl,
      },
    };
  };

  componentDidMount() {
    fetch(
      window.$ajaxPath + "/loadfiles.php?mail=" + this.props.match.params.email
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          files: response,
        });
        this.uploadedFieldCount = response.length;
      });

    fetch(
      window.$ajaxPath + "/loaddba.php?mail=" + this.props.match.params.email
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          dbaname: response.name,
        });
      });

    fetch(
      window.$ajaxPath +
        "/loadleaddocfield.php?mail=" +
        this.props.match.params.email
    )
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          fields: response,
        });

        this.fieldCount = 0;

        for (const field of response) {
          if (field.value && this.getDoclabel(field.key)) this.fieldCount++;
        }
      });

    fetch(window.$ajaxPath + "/fetchLabelsName.php")
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          fieldMaps: response,
        });
      });
  }

  getDoclabel = (id) => {
    if (this.state.fieldMaps[id]) return this.state.fieldMaps[id];

    return false;
  };

  render() {
    return (
      <div className="the-form" style={{ marginTop: 90 }}>
        <h3 className="text-center">{l("Welcome to GroovePay")}</h3>
        <h4 className="text-center">{l("Secured Document Portal")}</h4>

        <p className="message">
          <img src={Shield} />
          {l("Hi")} {this.state.dbaname},<br />
          {l(
            "In order to expedite your application and to comply with the credit cards rules and regulations, please upload a copy of the following supporting documents:"
          )}
        </p>

        <div className="my_drops">
          {this.state.fields.map((field) => {
            let file = false;

            for (const apiFile of this.state.files) {
              if (parseInt(this.getDoclabel(field.key).dlid) === apiFile.id)
                file = true;
            }

            return (
              field.value &&
              this.getDoclabel(field.key) && (
                <div key={field.key} className="my_drop">
                  {(file && (
                    <div className="dzu-dropzone">
                      <div className="dzu-previewContainer"></div>
                    </div>
                  )) || (
                    <div
                      className="drop-area"
                      data-tip
                      data-for={`tooltip-${field.key}`}
                    >
                      {this.getDoclabel(field.key).fdsc && (
                        <ReactTooltip id={`tooltip-${field.key}`} type="dark">
                          <div style={{ width: 250 }}>
                            {this.getDoclabel(field.key).fdsc}
                          </div>
                        </ReactTooltip>
                      )}
                      <Dropzone
                        getUploadParams={({ meta }) =>
                          this.getUploadParams(this.getDoclabel(field.key))
                        }
                        onChangeStatus={(fileWithMeta, status) => {
                          if (status === "done") {
                            let st = 1;
                            this.uploadedFieldCount++;

                            if (this.uploadedFieldCount >= this.fieldCount)
                              st = 2;

                            fetch(
                              window.$ajaxPath +
                                "/updatelead.php?st=" +
                                st +
                                "&mail=" +
                                this.props.match.params.email
                            );
                          }
                        }}
                        initialFiles={(file && [file]) || false}
                        multiple={false}
                        accept=".pdf,.doc,image/*"
                        inputContent={this.getDoclabel(field.key).ftl}
                      />
                    </div>
                  )}
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  }
}
class MapLabels extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsMap: {},
      fields: [],
      files: [],
    };
  }

  componentDidMount() {
    fetch(window.$ajaxPath + "/loadleaddocfield.php?mail=eml@1000.com")
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          fields: response,
        });
      });

    fetch(window.$ajaxPath + "/fetchLabelsName.php")
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          fieldsMap: response,
        });
      });

    fetch(window.$ajaxPath + "/loadfiles.php?mail=eml@1000.com")
      .then((res) => res.json())
      .then((response) => {
        this.setState({
          files: response,
        });
      });
  }

  updateField = (id, input, value) => {
    const currentFields = this.state.fieldsMap;

    if (currentFields[id]) currentFields[id][input] = value;
    else currentFields[id] = { [input]: value };

    this.setState(currentFields);
  };

  render() {
    return (
      <div className="the-form" style={{ paddingBottom: 50 }}>
        <h3 className="text-center">
          Map documents labels & Lead documents checkboxes
        </h3>
        <h4 className="text-center" style={{ marginBottom: 50 }}>
          The only way that can makes your life easier. Made with love by Hosny
        </h4>

        {this.state.fields.map((field) => (
          <div
            className="FormCard"
            key={field.key}
            style={{ marginBottom: 20 }}
          >
            <div className="Row">
              <div className="FillParent">
                <div className="InputLabel">Field ID*</div>
                <input
                  type="number"
                  className="myinput"
                  value={field.key}
                  onChange={(e) =>
                    this.updateField(field.key, "fid", e.target.value)
                  }
                />
              </div>
              <div className="FillParent">
                <div className="InputLabel">Document Label*</div>
                <Select
                  onChange={(e) =>
                    this.updateField(field.key, "dlid", e.target.value)
                  }
                  value={
                    this.state.fieldsMap[field.key] &&
                    this.state.fieldsMap[field.key].dlid
                  }
                >
                  <option value="">Select label</option>
                  {this.state.files.map((label, key) => (
                    <option key={key} value={label.id}>
                      {label.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="FillParent">
                <div className="InputLabel">Field title*</div>
                <input
                  type="text"
                  className="myinput"
                  value={
                    this.state.fieldsMap[field.key] &&
                    this.state.fieldsMap[field.key].ftl
                  }
                  onChange={(e) =>
                    this.updateField(field.key, "ftl", e.target.value)
                  }
                />
              </div>
              <div className="FillParent">
                <div className="InputLabel">Field description*</div>
                <input
                  type="text"
                  className="myinput"
                  value={
                    this.state.fieldsMap[field.key] &&
                    this.state.fieldsMap[field.key].fdsc
                  }
                  onChange={(e) =>
                    this.updateField(field.key, "fdsc", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        ))}

        <div className="FormNavigation Row Reverse">
          <button
            className="mybtn"
            onClick={(e) => {
              e.preventDefault();
              fetch(window.$ajaxPath + "/fetchLabelsName.php", {
                method: "POST",
                body: JSON.stringify({
                  action: "set",
                  fields: JSON.stringify(this.state.fieldsMap),
                }),
              })
                .then((res) => res.json())
                .then((response) => {
                  alert(response.answer);
                });
            }}
          >
            Map fields
          </button>
        </div>
      </div>
    );
  }
}

export default App;
