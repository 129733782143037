import Brazil from './translation/brazil.json';

export const l = (string) => {
    if( window.lang && window.lang !== 'us' && window.lang !== 'other' ) {
        let Translation = {};
        switch (window.lang) {
            case 'en':
                Translation = {};
                break;
    
            case 'br':
                Translation = Brazil;
                break;
                
            default:
                Translation = {};
                break;
        }
        return Translation[string] || string;
    }else
        return string;
}