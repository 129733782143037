import React, { useState, forwardRef, useImperativeHandle } from "react";
import {
  TextInput,
  Alert,
  Select,
  Textarea,
  Combobox,
  Button,
} from "evergreen-ui";
import { getCountries, mac_os_platforms } from "../../appl.constants";
import FormData from "../../services/form-data-collector.service";
import { l } from "../../Functions";

// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.

export default forwardRef(function OwnerInformationForm(props, ref) {
  let bis_ownrs_src = FormData.bis_ownrs || [];
  bis_ownrs_src[0] = getPrimaryOwnerDetails();
  const [bis_ownrs, setbis_ownrs] = useState(bis_ownrs_src);
  const [missingElement, setMElement] = useState(FormData.mElement || []);

  const bis_ownrs_forms = bis_ownrs.map((bis_ownr, key) => (
    <div className="BisOwnerFormCard FormCard" key={key}>
      <div className="Heading">
        {l("Owner / Officer")} {bis_ownrs && bis_ownrs.length > 1 && key + 1}
      </div>
      <BusinessOwnerForm
        index={key}
        path={props.path}
        formData={bis_ownr}
        formDataUpdate={(prop, val) => {
          updateFormDataForBisOwnr(key, bis_ownr, prop, val);
          publishDataToCollector();
        }}
      />
    </div>
  ));

  function updateFormDataForBisOwnr(bis_ownr_idx, bis_ownr, prop, val) {
    bis_ownr[prop] = val;
    let updtd_bis_ownrs = [...bis_ownrs];
    updtd_bis_ownrs[bis_ownr_idx] = bis_ownr;

    setbis_ownrs(updtd_bis_ownrs);
  }

  function addNewBisOwner() {
    let updtd_bis_ownrs = [...bis_ownrs];
    updtd_bis_ownrs.push(new BisOwnrFormScaffold());
    setbis_ownrs(updtd_bis_ownrs);
  }

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    triggerPublishData() {
      // publishDataToCollector();
      setMElement(FormData.mElement);
    },
  }));

  function publishDataToCollector() {
    const firstOwner = bis_ownrs[0] || null;
    const secondOwner = bis_ownrs[1] || null;

    publishPrimaryOwnerDetails(firstOwner);
    FormData.bis_ownrs = [...bis_ownrs];

    FormData.ownrctrl_fname = firstOwner.ownr_frst_nm || "";
    FormData.ownrctrl_lname = firstOwner.ownr_last_nm || "";
    FormData.ownrctrl_title = firstOwner.ownr_title || "";
    FormData.ownrctrl_ownrsh = firstOwner.ownrshp_pcnt || "";
    FormData.ownrctrl_dbirth = firstOwner.ownr_dob || "";
    FormData.ownrctrl_adr = firstOwner.ownr_addr || "";
    FormData.ownrctrl_city = firstOwner.ownr_addr_city || "";
    FormData.ownrctrl_state = firstOwner.ownr_addr_state || "";
    FormData.ownrctrl_zip = firstOwner.ownr_addr_zip || "";
    FormData.ownrctrl_phone = firstOwner.ownr_phn || "";
    FormData.ownrctrl_ssn = firstOwner.ownr_ssn || "";
    FormData.ownrctrlbr_cpf = firstOwner.ownrbr_cpf || "";
    FormData.ownrctrlbr_rg = firstOwner.ownrbr_rg || "";

    FormData.ownr1_csze = "";
    if (firstOwner) {
      if (firstOwner.ownr_addr_city)
        FormData.ownr1_csze += firstOwner.ownr_addr_city;

      if (firstOwner.ownr_addr_state)
        FormData.ownr1_csze += ", " + firstOwner.ownr_addr_state;

      if (firstOwner.ownr_addr_zip)
        FormData.ownr1_csze += ", " + firstOwner.ownr_addr_zip;
    }

    FormData.ownr2_csze = "";
    if (secondOwner) {
      if (secondOwner.ownr_addr_city)
        FormData.ownr2_csze += secondOwner.ownr_addr_city;

      if (secondOwner.ownr_addr_state)
        FormData.ownr2_csze += ", " + secondOwner.ownr_addr_state;

      if (secondOwner.ownr_addr_zip)
        FormData.ownr2_csze += ", " + secondOwner.ownr_addr_zip;
    }
  }

  publishDataToCollector();

  return (
    <React.Fragment>
      <div className="OwnerInfoAlert">
        <Alert
          intent="none"
          title={l(
            "Please provide information for each owner with 25% or more ownership. Otherwise, please complete for at least 1 officer."
          )}
        />
      </div>

      {bis_ownrs_forms}

      <Button
        onClick={addNewBisOwner}
        appearance="minimal"
        iconBefore="plus"
        className="custom-btn"
      >
        {l("Add Additional Owner")}
      </Button>
    </React.Fragment>
  );
});

function BusinessOwnerForm(props) {
  const is_mac_os = mac_os_platforms.includes(window.navigator.platform);

  function isChargeback() {
    return props.path === "/chargebacks";
  }

  function isAffiliates() {
    return props.path === "/affiliates";
  }

  function isAmerican() {
    return props.formData.ownr_cntry === "United States of America";
  }

  function isBrazilian() {
    return props.formData.ownr_cntry === "Brasil";
  }

  function inArray(needle, haystack) {
    if (needle && haystack) {
      var length = haystack.length;
      for (var i = 0; i < length; i++) {
        if (haystack[i] == needle) return true;
      }
    }

    return false;
  }

  function isFieldNotFilled(field) {
    return (
      FormData.mElementOwners[props.index] &&
      inArray(field, FormData.mElementOwners[props.index]) &&
      !props.formData[field]
    );
  }

  return (
    <React.Fragment>
      {((isChargeback() || isAffiliates()) && (
        <div className="Row">
          <div className="FillParent">
            <div className="InputLabel">{l("First name")}*</div>
            <TextInput
              value={props.formData.ownr_frst_nm || ""}
              onChange={(e) => {
                props.formDataUpdate("ownr_frst_nm", e.target.value);
                props.formDataUpdate(
                  "ownr_full_nm",
                  e.target.value +
                    " " +
                    props.formData.ownr_middle_nm +
                    " " +
                    props.formData.ownr_last_nm
                );
              }}
              className={isFieldNotFilled("ownr_frst_nm") ? "missing" : ""}
            />
          </div>
          <div className="FillParent">
            <div className="InputLabel">{l("Last name")}*</div>
            <TextInput
              value={props.formData.ownr_last_nm || ""}
              className={isFieldNotFilled("ownr_last_nm") ? "missing" : ""}
              onChange={(e) => {
                props.formDataUpdate("ownr_last_nm", e.target.value);
                props.formDataUpdate(
                  "ownr_full_nm",
                  props.formData.ownr_frst_nm +
                    " " +
                    props.formData.ownr_middle_nm +
                    " " +
                    e.target.value
                );
              }}
            />
          </div>
          <div className="FillParent">
            <div className="InputLabel">{l("Best phone number")}*</div>
            <TextInput
              name="ownr_phn"
              className={isFieldNotFilled("ownr_phn") ? "missing" : ""}
              value={props.formData.ownr_phn || ""}
              onChange={(e) => props.formDataUpdate("ownr_phn", e.target.value)}
            />
          </div>
        </div>
      )) || (
        <div>
          <div className="Row">
            <div className="FillParent">
              <div className="InputLabel">{l("First name")}*</div>
              <TextInput
                value={props.formData.ownr_frst_nm || ""}
                onChange={(e) => {
                  props.formDataUpdate("ownr_frst_nm", e.target.value);
                  props.formDataUpdate(
                    "ownr_full_nm",
                    e.target.value +
                      " " +
                      props.formData.ownr_middle_nm +
                      " " +
                      props.formData.ownr_last_nm
                  );
                }}
                className={isFieldNotFilled("ownr_frst_nm") ? "missing" : ""}
              />
            </div>
            <div className="FillParent" style={{ width: 100, flex: "none" }}>
              <div className="InputLabel">{l("Middle initial")}</div>
              <TextInput
                value={props.formData.ownr_middle_nm || ""}
                onChange={(e) => {
                  props.formDataUpdate("ownr_middle_nm", e.target.value);
                  props.formDataUpdate(
                    "ownr_full_nm",
                    props.formData.ownr_frst_nm +
                      " " +
                      e.target.value +
                      " " +
                      props.formData.ownr_last_nm
                  );
                }}
              />
            </div>
            <div className="FillParent">
              <div className="InputLabel">{l("Last name")}*</div>
              <TextInput
                value={props.formData.ownr_last_nm || ""}
                className={isFieldNotFilled("ownr_last_nm") ? "missing" : ""}
                onChange={(e) => {
                  props.formDataUpdate("ownr_last_nm", e.target.value);
                  props.formDataUpdate(
                    "ownr_full_nm",
                    props.formData.ownr_frst_nm +
                      " " +
                      props.formData.ownr_middle_nm +
                      " " +
                      e.target.value
                  );
                }}
              />
            </div>
            <div className="FillParent">
              <div className="InputLabel">{l("Date of Birth")}*</div>
              <TextInput
                type="date"
                name="ownr_dob"
                value={props.formData.ownr_dob || ""}
                className={isFieldNotFilled("ownr_dob") ? "missing" : ""}
                onChange={(e) =>
                  props.formDataUpdate("ownr_dob", e.target.value)
                }
              />
            </div>
          </div>
          <div className="Row">
            <div className="FillParent" style={{ width: 180, flex: "none" }}>
              <div className="InputLabel">{l("Title")}*</div>
              <Select
                value={props.formData.ownr_title || ""}
                placeholder={l("# of processing statements")}
                onChange={(e) =>
                  props.formDataUpdate("ownr_title", e.target.value)
                }
                className={isFieldNotFilled("ownr_title") ? "missing" : ""}
              >
                <option value="">{l("(Please Select)")}</option>
                <option value="President">{l("President")}</option>
                <option value="Vice President">{l("Vice President")}</option>
                <option value="Treasurer">{l("Treasurer")}</option>
                <option value="Owner">{l("Owner")}</option>
                <option value="Partner">{l("Partner")}</option>
                <option value="CEO">{l("CEO")}</option>
                <option value="Secretary">{l("Secretary")}</option>
                <option value="Director">{l("Director")}</option>
                <option value="Authorized Officer">
                  {l("Authorized Officer")}
                </option>
              </Select>
            </div>
            <div className="FillParent" style={{ width: 100, flex: "none" }}>
              <div className="InputLabel">{l("Ownership (%)")}*</div>
              <TextInput
                type="number"
                name="ownrshp_pcnt"
                max={100}
                min={0}
                value={props.formData.ownrshp_pcnt || ""}
                className={isFieldNotFilled("ownrshp_pcnt") ? "missing" : ""}
                onChange={(e) =>
                  props.formDataUpdate("ownrshp_pcnt", e.target.value)
                }
              />
            </div>
            <div className="FillParent">
              <div className="InputLabel">{l("Best email")}*</div>
              <TextInput
                name="ownr_email"
                value={props.formData.ownr_email || ""}
                className={isFieldNotFilled("ownr_email") ? "missing" : ""}
                onChange={(e) =>
                  props.formDataUpdate("ownr_email", e.target.value)
                }
              />
            </div>
            <div className="FillParent">
              <div className="InputLabel">{l("Best phone number")}*</div>
              <TextInput
                name="ownr_phn"
                value={props.formData.ownr_phn || ""}
                className={isFieldNotFilled("ownr_phn") ? "missing" : ""}
                onChange={(e) =>
                  props.formDataUpdate("ownr_phn", e.target.value)
                }
              />
            </div>
          </div>
          <div className="Row">
            <div className="FillParent" style={{ width: 150, flex: "none" }}>
              <div className="InputLabel">{l("Country")}</div>
              <Combobox
                openOnFocus
                items={getCountries()}
                autocompleteProps={{
                  title: l("Choose Country"),
                }}
                placeholder={l("(Please Select)")}
                initialSelectedItem={props.formData.ownr_cntry}
                onChange={(selected) => {
                  props.formDataUpdate("ownr_cntry", selected);
                  !isAmerican() && props.formDataUpdate("ownr_ssn", "");
                }}
              ></Combobox>
            </div>
            <div className="FillParent">
              <div className="InputLabel">{l("Home Address")}*</div>
              <TextInput
                resize="none"
                name="ownr_addr"
                value={props.formData.ownr_addr || ""}
                className={isFieldNotFilled("ownr_addr") ? "missing" : ""}
                onChange={(e) =>
                  props.formDataUpdate("ownr_addr", e.target.value)
                }
              />
            </div>
            <div className="FillParent" style={{ width: 120, flex: "none" }}>
              <div className="InputLabel">{l("City")}*</div>
              <TextInput
                value={props.formData.ownr_addr_city || ""}
                className={isFieldNotFilled("ownr_addr_city") ? "missing" : ""}
                onChange={(e) =>
                  props.formDataUpdate("ownr_addr_city", e.target.value)
                }
              />
            </div>

            <div className="FillParent" style={{ width: 120, flex: "none" }}>
              <div className="InputLabel">{l("State")}</div>
              {(props.formData.ownr_cntry === "United States of America" && (
                <Select
                  value={props.formData.ownr_addr_state || ""}
                  className={
                    isFieldNotFilled("ownr_addr_state") ? "missing" : ""
                  }
                  onChange={(e) =>
                    props.formDataUpdate("ownr_addr_state", e.target.value)
                  }
                >
                  <option value="">{l("State")}</option>
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AR">AR</option>
                  <option value="AZ">AZ</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DC">DC</option>
                  <option value="DE">DE</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="IA">IA</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="MA">MA</option>
                  <option value="MD">MD</option>
                  <option value="ME">ME</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MO">MO</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="NC">NC</option>
                  <option value="NE">NE</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NV">NV</option>
                  <option value="NY">NY</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WI">WI</option>
                  <option value="WV">WV</option>
                  <option value="WY">WY</option>
                </Select>
              )) || (
                <TextInput
                  value={props.formData.ownr_addr_state || ""}
                  onChange={(e) =>
                    props.formDataUpdate("ownr_addr_state", e.target.value)
                  }
                />
              )}
            </div>

            <div className="FillParent" style={{ width: 120, flex: "none" }}>
              <div className="InputLabel">{l("Zip Code")}</div>
              <TextInput
                value={props.formData.ownr_addr_zip || ""}
                onChange={(e) =>
                  props.formDataUpdate("ownr_addr_zip", e.target.value)
                }
              />
            </div>
          </div>
          <div className="Row">
            <div className="FillParent">
              <div className="Row">
                {isAmerican() && (
                  <div className="FillParent">
                    <div className="InputLabel">
                      {l("Social Security Number")}*
                    </div>
                    <TextInput
                      type="password"
                      value={props.formData.ownr_ssn || ""}
                      onChange={(e) =>
                        props.formDataUpdate("ownr_ssn", e.target.value)
                      }
                      className={isFieldNotFilled("ownr_ssn") ? "missing" : ""}
                    />
                  </div>
                )}
                <div className="FillParent">
                  <div className="InputLabel">{l("Driver License")}*</div>
                  <TextInput
                    value={props.formData.ownr_drv_lis || ""}
                    onChange={(e) =>
                      props.formDataUpdate("ownr_drv_lis", e.target.value)
                    }
                    className={
                      isFieldNotFilled("ownr_drv_lis") ? "missing" : ""
                    }
                  />
                </div>
                {isAmerican() && (
                  <div className="FillParent">
                    <div className="InputLabel">{l("Driver State")}</div>
                    <Select
                      name="ownr_drv_state"
                      value={props.formData.ownr_drv_state || ""}
                      onChange={(e) =>
                        props.formDataUpdate("ownr_drv_state", e.target.value)
                      }
                    >
                      <option value="">{l("State")}</option>
                      <option value="AL">AL</option>
                      <option value="AK">AK</option>
                      <option value="AR">AR</option>
                      <option value="AZ">AZ</option>
                      <option value="CA">CA</option>
                      <option value="CO">CO</option>
                      <option value="CT">CT</option>
                      <option value="DC">DC</option>
                      <option value="DE">DE</option>
                      <option value="FL">FL</option>
                      <option value="GA">GA</option>
                      <option value="HI">HI</option>
                      <option value="IA">IA</option>
                      <option value="ID">ID</option>
                      <option value="IL">IL</option>
                      <option value="IN">IN</option>
                      <option value="KS">KS</option>
                      <option value="KY">KY</option>
                      <option value="LA">LA</option>
                      <option value="MA">MA</option>
                      <option value="MD">MD</option>
                      <option value="ME">ME</option>
                      <option value="MI">MI</option>
                      <option value="MN">MN</option>
                      <option value="MO">MO</option>
                      <option value="MS">MS</option>
                      <option value="MT">MT</option>
                      <option value="NC">NC</option>
                      <option value="NE">NE</option>
                      <option value="NH">NH</option>
                      <option value="NJ">NJ</option>
                      <option value="NM">NM</option>
                      <option value="NV">NV</option>
                      <option value="NY">NY</option>
                      <option value="ND">ND</option>
                      <option value="OH">OH</option>
                      <option value="OK">OK</option>
                      <option value="OR">OR</option>
                      <option value="PA">PA</option>
                      <option value="RI">RI</option>
                      <option value="SC">SC</option>
                      <option value="SD">SD</option>
                      <option value="TN">TN</option>
                      <option value="TX">TX</option>
                      <option value="UT">UT</option>
                      <option value="VT">VT</option>
                      <option value="VA">VA</option>
                      <option value="WA">WA</option>
                      <option value="WI">WI</option>
                      <option value="WV">WV</option>
                      <option value="WY">WY</option>
                    </Select>
                  </div>
                )}
                {!isAmerican() && !isBrazilian() && (
                  <div className="FillParent">
                    <div className="InputLabel">{l("Passport Number")}*</div>
                    <TextInput
                      value={props.formData.ownr_passport_number || ""}
                      onChange={(e) =>
                        props.formDataUpdate(
                          "ownr_passport_number",
                          e.target.value
                        )
                      }
                      className={
                        isFieldNotFilled("ownr_passport_number")
                          ? "missing"
                          : ""
                      }
                    />
                  </div>
                )}
                {!isAmerican() && !isBrazilian() && (
                  <div className="FillParent">
                    <div className="InputLabel">{l("Issuing Country")}</div>
                    <TextInput
                      value={props.formData.ownr_issuing_country || ""}
                      onChange={(e) =>
                        props.formDataUpdate(
                          "ownr_issuing_country",
                          e.target.value
                        )
                      }
                    />
                  </div>
                )}

                {isBrazilian() && (
                  <div className="FillParent">
                    <div className="InputLabel">{l("CPF")}*</div>
                    <TextInput
                      value={props.formData.ownrbr_cpf || ""}
                      onChange={(e) =>
                        props.formDataUpdate("ownrbr_cpf", e.target.value)
                      }
                      className={
                        isFieldNotFilled("ownrbr_cpf") ? "missing" : ""
                      }
                    />
                  </div>
                )}
                {isBrazilian() && (
                  <div className="FillParent">
                    <div className="InputLabel">{l("RG")}*</div>
                    <TextInput
                      value={props.formData.ownrbr_rg || ""}
                      onChange={(e) =>
                        props.formDataUpdate("ownrbr_rg", e.target.value)
                      }
                      className={isFieldNotFilled("ownrbr_rg") ? "missing" : ""}
                    />
                  </div>
                )}
                {!isBrazilian() && (
                  <div className="FillParent">
                    <div className="InputLabel">{l("Expiration date")}</div>
                    <TextInput
                      type="date"
                      value={props.formData.ownr_doc_expiration_date || ""}
                      onChange={(e) =>
                        props.formDataUpdate(
                          "ownr_doc_expiration_date",
                          e.target.value
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

function BisOwnrFormScaffold(data) {
  this.ownr_email = data ? data.ownr_email : "";
  this.ownr_frst_nm = data ? data.ownr_frst_nm : "";
  this.ownr_middle_nm = data ? data.ownr_middle_nm : "";
  this.ownr_last_nm = data ? data.ownr_last_nm : "";
  this.ownr_full_nm = data ? data.ownr_full_nm : "";
  this.ownr_title = data ? data.ownr_title : "";
  this.ownrshp_pcnt = data ? data.ownrshp_pcnt : "";
  this.ownr_dob = data ? data.ownr_dob : "";
  this.ownr_ssn = data ? data.ownr_ssn : "";
  this.ownr_addr = data ? data.ownr_addr : "";
  this.ownr_addr_city = data ? data.ownr_addr_city : "";
  this.ownr_addr_state = data ? data.ownr_addr_state : "";
  this.ownr_addr_zip = data ? data.ownr_addr_zip : "";
  this.ownr_cntry = data ? data.ownr_cntry : "";
  this.ownr_phn = data ? data.ownr_phn : "";
  this.ownr_drv_lis = data ? data.ownr_drv_lis : "";
  this.ownr_drv_state = data ? data.ownr_drv_state : "";
  this.ownr_passport_number = data ? data.ownr_passport_number : "";
  this.ownr_issuing_country = data ? data.ownr_issuing_country : "";
  this.ownr_doc_expiration_date = data ? data.ownr_doc_expiration_date : "";
}

function getPrimaryOwnerDetails() {
  return new BisOwnrFormScaffold({
    ownr_email: FormData.ownr_mail || "",
    ownr_frst_nm: FormData.ownr_frst_nm || FormData.own_frst_nm || "",
    ownr_middle_nm: FormData.ownr_middle_nm || "",
    ownr_last_nm: FormData.ownr_last_nm || FormData.own_last_nm || "",
    ownr_full_nm: FormData.ownr_full_nm || "",
    ownr_title: "",
    ownrshp_pcnt: "",
    ownr_dob: "",
    ownr_ssn: "",
    ownrbr_cpf: "",
    ownrbr_rg: "",
    ownr_addr: FormData.ownr_addr || "",
    ownr_addr_city: FormData.ownr_addr_city || "",
    ownr_addr_state: FormData.ownr_addr_state || FormData.dba_adr_state || "",
    ownr_addr_zip: FormData.ownr_addr_zip || "",
    ownr_cntry: FormData.ownr_cntry || "",
    ownr_phn: FormData.cust_sup_phn || "",
    ownr_drv_lis: FormData.ownr_drv_lis || "",
    ownr_drv_state: FormData.ownr_drv_state || "",
    ownr_passport_number: FormData.ownr_passport_number || "",
    ownr_issuing_country: FormData.ownr_issuing_country || "",
    ownr_doc_expiration_date: FormData.ownr_doc_expiration_date || "",
  });
}

function publishPrimaryOwnerDetails(bis_ownr) {
  FormData.ownr_mail = bis_ownr.ownr_email;
  FormData.ownr_frst_nm = bis_ownr.ownr_frst_nm;
  FormData.ownr_middle_nm = bis_ownr.ownr_middle_nm;
  FormData.ownr_last_nm = bis_ownr.ownr_last_nm;
  FormData.ownr_full_nm = bis_ownr.ownr_full_nm;

  FormData.ownr_addr = bis_ownr.ownr_addr;
  FormData.ownr_addr_city = bis_ownr.ownr_addr_city;
  FormData.ownr_addr_state = bis_ownr.ownr_addr_state;
  FormData.ownr_addr_zip = bis_ownr.ownr_addr_zip;
  FormData.ownr_cntry = bis_ownr.ownr_cntry;
  FormData.ownr_phn = bis_ownr.ownr_phn;

  FormData.ownr_drv_lis = bis_ownr.ownr_drv_lis || "";
  FormData.ownr_drv_state = bis_ownr.ownr_drv_state || "";
  FormData.ownr_passport_number = bis_ownr.ownr_passport_number || "";
  FormData.ownr_issuing_country = bis_ownr.ownr_issuing_country || "";

  FormData.ownr_doc_expiration_date = bis_ownr.ownr_doc_expiration_date || "";
  FormData.ownrbr_cpf = bis_ownr.ownrbr_cpf || "";
  FormData.ownrbr_rg = bis_ownr.ownrbr_rg || "";
}
