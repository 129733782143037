function FormDataScaffold(){
    this.bis_info_moto = 0;
    this.bis_info_b2b = 0;
    this.bis_info_imprnt = 0;
    this.bis_info_intl_card_prcnt = 5;
    this.site_survey_nt_phys_inspctd = 1;
    this.site_survey_appr_as_rprsntd = "Yes";
    this.site_survey_opn_oprtng = "Yes";
    this.site_survey_invtry_suff = "Yes";
    this.site_survey_dlvry_at_tm_of_sl = "Yes";
    this.site_survey_guds_chrgd_on = "Order";
    this.fin_prof_dpst_tm_frm = "Premium ACH";
    this.fin_prof_dpst_typ = "By Batch";
    this.cmplnc_card_info_strd_sw = "No";
    this.cmplnc_sfw_pci_complaints = "Yes";
    this.procss_stmnt_cnt = 3;

    return this;
}

export default new FormDataScaffold();