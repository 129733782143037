import React from 'react';
import './FormSection.css';

import FormCard from '../form-card/FormCard';


export default function FormSection(props){
    return (
        <div className="Row FormSection" data-section-active={props.active}>
            <FormCard 
                bindToChildFormRef={props.bindToChildFormRef}
                heading={props.formHeading} 
                description={props.formDescription}
                {...props}
            />
        </div>
    );
}