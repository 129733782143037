class MyEvent {
    constructor() {
      // supported events
        this.event = {
            update : []
        };
    }

    update = () => {
        // trigger all events
        this.event.update.forEach(e => e());
    };

    /**
     * function to add event
     * @param {String} eventName
     * @param {function} cb - callback
     */
    addEventListener = (eventName, cb = () => {}) => {
        let returnCB = null;
    
        if (typeof cb !== 'function') {
            console.error('`cb` param has to be function');
            return false;
        }
    
        if (Object.prototype.hasOwnProperty.call(this.event, eventName)) {
            this.event[eventName].push(cb);
    
            returnCB = cb;
        } else {
            console.error('No such event');
        }
    
        return returnCB;
    };
  
    /**
     * function to remove event
     * @param {String} eventName
     * @param {function} cb - callback
     */
    removeEventListener = (eventName, cb) => {
        if (Object.prototype.hasOwnProperty.call(this.event, eventName)) {
            this.event[eventName] = this.event[eventName].filter(e => e !== cb);
        } else {
            console.error('No such event');
        }
    };
}

const gkevent = new MyEvent();

export default gkevent;