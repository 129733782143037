import React, {useState, forwardRef, useImperativeHandle} from 'react';

import {Select, TextInput} from 'evergreen-ui';
import FormData from '../../services/form-data-collector.service';
import {l} from '../../Functions';

// We need to wrap component in `forwardRef` in order to gain
// access to the ref object that is assigned using the `ref` prop.
// This ref is passed as the second parameter to the function component.

export default forwardRef(function FinancialProfileForm(props, ref){
    const [acc_typ, setacc_typ] = useState(FormData.acc_typ || '');
    const [acc_nmbr, setacc_nmbr] = useState(FormData.acc_nmbr || '');
    const [rtng_nmbr, setrtng_nmbr] = useState(FormData.rtng_nmbr || '');
    const [br_banco, setbr_banco] = useState(FormData.br_banco || '');
    const [br_agencia, setbr_agencia] = useState(FormData.br_agencia || '');
    const [br_cc, setbr_cc] = useState(FormData.br_cc || '');
    const [br_cnpj_cpf, setbr_cnpj_cpf] = useState(FormData.br_cnpj_cpf || '');
    const [br_beneficiario, setbr_beneficiario] = useState(FormData.br_beneficiario || '');
    const [statementdescriptor, setstatementdescriptor] = useState(FormData.statementdescriptor || '');
    const [statementdescriptor2, setstatementdescriptor2] = useState(FormData.statementdescriptor2 || '');
    const [statementdescriptor3, setstatementdescriptor3] = useState(FormData.statementdescriptor3 || '');
    const [statementdescriptor4, setstatementdescriptor4] = useState(FormData.statementdescriptor4 || '');
    const [missingElement, setMElement]   = useState(FormData.mElement || []);


    // The component instance will be extended
    // with whatever you return from the callback passed
    // as the second argument
    useImperativeHandle(ref, () => ({
        triggerPublishData(){
            publishDataToCollector();
            setMElement(FormData.mElement);
        }
    }));

    function publishDataToCollector(){
        FormData.acc_typ = acc_typ;
        FormData.acc_nmbr = acc_nmbr;
        FormData.rtng_nmbr = rtng_nmbr;
        FormData.statementdescriptor = statementdescriptor;
        FormData.statementdescriptor2 = statementdescriptor2;
        FormData.statementdescriptor3 = statementdescriptor3;
        FormData.statementdescriptor4 = statementdescriptor4;

        fetch('https://www.routingnumbers.info/api/data.json?rn='+rtng_nmbr)
        .then(response => response.json())
        .then(data => {
            if( data.code === 200 )
                FormData.bank_name = data.customer_name;
        });
    }

    function isChargeback(){
        return props.path === '/chargebacks'
    }

    function isAffiliates() {
        return props.path === '/affiliates'
    }

    function isBrazilian(){
        return props.country === 'br'
    }
    
    function isFieldNotFilled(target) {
        return inArray(target,missingElement) && !FormData[target];
    }

	function inArray(needle, haystack) {
        if( needle && haystack ){
            var length = haystack.length;
            for(var i = 0; i < length; i++) {
                if(haystack[i] == needle) return true;
            }
        }

        return false;
    }
    
    return(
        <React.Fragment>
            {
                isBrazilian() && 
                <div className="Row">
                    <div className="FillParent">
                        <div className="InputLabel">{l('Banco')}*</div>
                        <TextInput value={br_banco} onChange={e => {setbr_banco(e.target.value); FormData.br_banco = e.target.value;}} type="text" className={isFieldNotFilled('br_banco')?'missing':''} />
                    </div>

                    <div className="FillParent">
                        <div className="InputLabel">{l('AGÊNCIA')}*</div>
                        <TextInput value={br_agencia} onChange={e => {setbr_agencia(e.target.value); FormData.br_agencia = e.target.value;}} type="text" className={isFieldNotFilled('br_agencia')?'missing':''} />
                    </div>

                    <div className="FillParent">
                        <div className="InputLabel">{l('C/C')}*</div>
                        <TextInput value={br_cc} onChange={e => {setbr_cc(e.target.value); FormData.br_cc = e.target.value;}} type="text" className={isFieldNotFilled('br_cc')?'missing':''} />
                    </div>

                    <div className="FillParent">
                        <div className="InputLabel">{l('CNPJ/CPF')}*</div>
                        <TextInput value={br_cnpj_cpf} onChange={e => {setbr_cnpj_cpf(e.target.value); FormData.br_cnpj_cpf = e.target.value;}} type="text" className={isFieldNotFilled('br_cnpj_cpf')?'missing':''} />
                    </div>

                    <div className="FillParent">
                        <div className="InputLabel">{l('NOME DO BENEFICIÁRIO')}*</div>
                        <TextInput value={br_beneficiario} onChange={e => {setbr_beneficiario(e.target.value); FormData.br_beneficiario = e.target.value;}} type="text" className={isFieldNotFilled('br_beneficiario')?'missing':''} />
                    </div>
                </div> ||
                <div className="Row">
                    <div className="FillParent">
                        <div className="InputLabel">{l('Business routing number')}*</div>
                        <TextInput name="rtng_nmbr" value={rtng_nmbr} onChange={e => {setrtng_nmbr(e.target.value); FormData.rtng_nmbr = e.target.value;}} type="text" className={isFieldNotFilled('rtng_nmbr')?'missing':''} />
                    </div>

                    <div className="FillParent">
                        <div className="InputLabel">{l('Business account number')}*</div>
                        <TextInput name="acc_nmbr" value={acc_nmbr} onChange={e => {setacc_nmbr(e.target.value); FormData.acc_nmbr = e.target.value;}} type="text" className={isFieldNotFilled('acc_nmbr')?'missing':''} />
                    </div>

                    <div className="FillParent">
                        <div className="InputLabel MarginTop2x">{l('Account Type')}</div>
                        <Select name="acc_typ"
                            value={acc_typ}
                            onChange={e => setacc_typ(e.target.value)}  >
                            <option value="(Please Select)">{l('(Please Select)')}</option>
                            <option value="Checking">{l('Checking')}</option>
                            <option value="Savings">{l('Savings')}</option>
                        </Select>
                    </div>
                </div>
            }
            {
                isChargeback() && 
                <div className="Row">
                    <div className="FillParent">
                        <div className="InputLabel">{l('Statement Descriptor 01')}*</div>
                        <TextInput name="statementdescriptor" value={statementdescriptor} onChange={e => {setstatementdescriptor(e.target.value); FormData.statementdescriptor = e.target.value;}} type="text" className={isFieldNotFilled('statementdescriptor')?'missing':''} />
                    </div>
                    <div className="FillParent">
                        <div className="InputLabel">{l('Statement Descriptor 02')}*</div>
                        <TextInput name="statementdescriptor2" value={statementdescriptor2} onChange={e => {setstatementdescriptor2(e.target.value); FormData.statementdescriptor2 = e.target.value;}} type="text" className={isFieldNotFilled('statementdescriptor2')?'missing':''} />
                    </div>
                    <div className="FillParent">
                        <div className="InputLabel">{l('Statement Descriptor 03')}*</div>
                        <TextInput name="statementdescriptor3" value={statementdescriptor3} onChange={e => {setstatementdescriptor3(e.target.value); FormData.statementdescriptor3 = e.target.value;}} type="text" className={isFieldNotFilled('statementdescriptor3')?'missing':''} />
                    </div>
                    <div className="FillParent">
                        <div className="InputLabel">{l('Statement Descriptor 04')}*</div>
                        <TextInput name="statementdescriptor4" value={statementdescriptor4} onChange={e => {setstatementdescriptor4(e.target.value); FormData.statementdescriptor4 = e.target.value;}} type="text" className={isFieldNotFilled('statementdescriptor4')?'missing':''} />
                    </div>
                </div>
            }
        </React.Fragment>
    );
});